import {AxiosInstance} from "axios";
import {Certificate, CreateCertificateData, UpdateCertificateData} from "./types";


export default function CertificatesApiModule(instance: AxiosInstance) {
    const getUrl = (path: string) => 'certificates/' + path

    return {
        getList() {
            return instance.get<Array<Certificate>>(getUrl(''))
        },
        create(data: CreateCertificateData) {
            return instance.post<Certificate>(getUrl('create'), data)
        },
        update(certificateID: number, data: UpdateCertificateData) {
            return instance.patch<Certificate>(getUrl(certificateID + ''), data)
        },
        delete(certificateID: number) {
            return instance.delete<{id: number}>(getUrl(certificateID + ''))
        },
    }
}
