import {getMobileOperatingSystem} from "../helpers";
import {useOutletContext} from "react-router-dom";
import {LangContext} from "../types";
import AppStoreBadge from "./AppStoreBadge.svg";

export default function MarketLinks() {
    const OS = getMobileOperatingSystem()

    return (
        <div className={'px-3 w-100'} style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        }}>
            {OS !== 'ios' && <GooglePlayLink/>}
            {OS !== 'android' && <AppStoreLink/>}
        </div>
    )
}


export function GooglePlayLink() {
    const {lang} = useOutletContext<LangContext>()

    return (
        <a
            href={
                'https://play.google.com/store/apps/details' +
                '?id=com.liplep.app'
            }
            target={"_blank"}
            style={{
                width: 130,
            }}
        >
            <img
                alt={lang === 'uk' ? 'Завантажити з Google Play' : 'Get it on Google Play'}
                src={
                    lang === 'uk'
                        ? 'https://play.google.com/intl/en_us/badges/static/images/badges/ua_badge_web_generic.png'
                        : 'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                }
                className={'w-100'}
            />
        </a>
    )
}


export function AppStoreLink() {
    return (
        <a
            target={"_blank"}
            href={'https://apps.apple.com/app/liplep/id1665933840'}
        >
            <img src={AppStoreBadge} alt={'Get in on App store'}/>
        </a>
    )
}
