import React, {useState} from "react";
import {changePassword, checkIsTGConnected, sendConfirmEmail} from "../../api/user";
import {IsConfirmedChecker} from "../../confirmEmailWaiter";
import {TLoginButton, TLoginButtonSize} from "react-telegram-auth";
import {MDBBtn, MDBInput} from "mdb-react-ui-kit";
import useMergeState from "../../mergedState";
import {BotUsername} from "../../config";


export function NewPasswordForm( { needEmail, formData, formOnChange, onSubmit } ) {
    return (
        <form action={'#'} onSubmit={onSubmit}>
            {needEmail && (
                <div className={'mb-4'}>
                    <MDBInput
                        type={'email'}
                        label={'Enter your email'}
                        id={'newPasswordEmail'}
                        required
                        onChange={formOnChange('email')}
                    />
                </div>
            )}

            <div className={'mb-4'}>
                <MDBInput
                  type={'password'}
                  label={'New password'}
                  id={'newPassword'}
                  required
                  onChange={formOnChange('password')}
                  aria-describedby={'registerPasswordError'}
                />

                {formData.password && formData.password.length < 8 && (
                    <div id={'registerPasswordError'} className={'form-text text-danger'}>
                        Password must be minimum 8 symbols
                    </div>
                )}
            </div>

            <div className={'mb-4'}>
                <MDBInput
                  type={"password"}
                  label={'Repeat password'}
                  id={'confirmNewPassword'}
                  required
                  onChange={formOnChange('confirmPassword')}
                  aria-describedby={'confirmPasswordError'}
                />
                {formData.confirmPassword && formData.password && formData.password !== formData.confirmPassword && (
                    <div id={'confirmPasswordError'} className={'form-text text-danger'}>
                        Passwords do not match
                    </div>
                )}
            </div>

            <MDBBtn
                type="submit" block
                className="mb-3"
            >
                Change Password
            </MDBBtn>
        </form>
    )
}


export function ChangePassword( { me, lang, callbackFunc, closeModal, show=true } ) {
    const defaultState = {
        email: me ? me.email : '',
        password: '',
        confirmPassword: '',
    }

    const [formData, setFormData] = useMergeState(defaultState)
    const [isWaitForConfirmEmail, setIsWaitForConfirmEmail] = useState(false)
    const [isEmailConfirmed, setIsEmailConfirmed] = useState(false)

    const formOnChange = (field) => {
        return (event) => {
          const newData = {}
          newData[field] = event.target.value;
          setFormData(newData)
        }
    }

    const processChangePassword = (telegramData=null) => {
        changePassword(formData.email, telegramData, formData.password, lang).then(result => {
            setFormData(defaultState)
            if (callbackFunc) {
                callbackFunc({result: result, password: formData.password, email: formData.email})
            }
            if (closeModal) {
                closeModal()
            }
        })
    }

    const onPasswordFormSubmit = (event) => {
        event.preventDefault();

        sendConfirmEmail(formData.email, 'change_password', lang).then(result => {
            if (result) {
                setIsWaitForConfirmEmail(true)
                IsConfirmedChecker(result.token, () => {
                    checkIsTGConnected(formData.email, lang).then(
                        result => {
                            if (result.is_connected === true) {
                                setIsWaitForConfirmEmail(false)
                                setIsEmailConfirmed(true)
                            } else {
                                processChangePassword()
                            }
                        }
                    )
                })
            }
        })
    }

    const onTelegramLogin = (user) => {
        processChangePassword(user)
    }

    if (isEmailConfirmed === true) {
        return (
            <div>
                <h6>Confirm your telegram</h6>
                {show && <TLoginButton
                    botName={BotUsername}
                    buttonSize={TLoginButtonSize.Large}
                    lang={lang}
                    usePic={false}
                    cornerRadius={20}
                    onAuthCallback={onTelegramLogin}
                    requestAccess={'write'}
                />}
            </div>
        )
    }

    if (isWaitForConfirmEmail === true) {
        return <p>We sent confirmation letter to your email</p>

    }

    return (
        <NewPasswordForm
            formData={formData}
            needEmail={!me}
            formOnChange={formOnChange}
            onSubmit={onPasswordFormSubmit}
        />
    )

}
