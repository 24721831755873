import {TLoginButton, TLoginButtonSize, TUser} from "react-telegram-auth";
import {BotUsername} from "../../../config";


interface LoginViewProps {
    onLogin: (user: TUser) => any
    lang: string
    tgLoginText: string
    pressTgLoginButtonText: string
}


export default function LoginView({onLogin, lang, tgLoginText, pressTgLoginButtonText}: LoginViewProps) {
    return (
        <div className={'login-container'}>
            <div>
                <h3 className={'tg-login-text mb-3'}>
                    {tgLoginText}
                </h3>
                <p className={'login-container-text'}>
                    {pressTgLoginButtonText}
                </p>
            </div>
            <div className={'w-100 d-flex justify-content-center'}>
                <TLoginButton
                    botName={BotUsername}
                    buttonSize={TLoginButtonSize.Large}
                    lang={lang}
                    cornerRadius={20}
                    onAuthCallback={onLogin}
                    requestAccess={'write'}
                />
            </div>
        </div>
    )
}
