import {MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import useActiveSet from "../../hooks/useActiveShopperTemplatesSet";
import {useState} from "react";
import {
    SetTemplates,
} from "../../../../../../../api/ShopperTemplates/ShopperTemplatesSets/types";
import TemplatesFilters from "./TemplatesFilters";
import CreateTemplateCard, {CreateTemplatesCardTexts} from "./CreateTemplateCard";
import TemplateCard, {TemplateCardTexts} from "./TemplateCard";


interface TemplatesProps extends CreateTemplatesCardTexts, TemplateCardTexts {

}


export default function Templates(props: TemplatesProps) {
    const activeSet = useActiveSet()
    const [activeRarity, setActiveRarity] = useState<keyof SetTemplates>('common')

    if (!activeSet?.templates) {
        return null
    }

    return (
        <MDBContainer fluid>
            <TemplatesFilters
                activeRarity={activeRarity}
                setActiveRarity={setActiveRarity}
            />
            <MDBRow className={'w-100 mt-3'}>
                <MDBCol key={'create'} size={12} sm={6} lg={4} xl={3}>
                    <CreateTemplateCard
                        rarity={activeRarity}
                        createTemplateHeader={props.createTemplateHeader}
                        createTemplateButton={props.createTemplateButton}
                        isActiveLabel={props.isActiveLabel}
                        nameLabel={props.nameLabel}
                    />
                </MDBCol>

                {activeSet.templates[activeRarity] &&
                    activeSet.templates[activeRarity].map((template, idx) => (
                    <MDBCol key={template.id} size={12} sm={6} lg={4} xl={3}>
                        <TemplateCard
                            template={template}
                            rarity={activeRarity}
                            idx={idx}
                            nameLabel={props.nameLabel}
                            isActiveLabel={props.isActiveLabel}
                            deleteButton={props.deleteButton}
                        />
                    </MDBCol>
                ))}

            </MDBRow>
        </MDBContainer>
    )
}

