import {useEffect, useState} from "react";


export function useOnScreen(ref, rootMargin='0px') {
    const [isInteresting, setIsInteresting] = useState(false)

    const isRef = !!ref.current

    useEffect(() => {
        const element = ref.current

        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsInteresting(entry.isIntersecting)
            },
            {
                rootMargin: rootMargin
            }
        )
        if (isRef) {
            observer.observe(element)
        }

        return () => {
            if (element) {
                observer.unobserve(element)
            }
        }
    }, [isRef, rootMargin])

    return isInteresting
}

export const useDocumentTitle = (title='') => {
  const [documentTitle, setDocumentTitle] = useState(title);
   useEffect(() => {
    document.title = documentTitle;
  },[documentTitle]);

  return {documentTitle, setDocumentTitle};
};


export const useTG = () => {
    const WebApp = window.Telegram.WebApp
    return {
        WebApp: WebApp,
        data: WebApp.initDataUnsafe,
        hash: WebApp.initData,
        isTG: !!WebApp.initData,
    }
}
