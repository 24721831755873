import {useOutletContext, useParams} from "react-router-dom";
import {ShopperTemplatesContext} from "../../../types";
import {ShopperTemplatesSetType} from "../../../../../../api/ShopperTemplates/ShopperTemplatesSets/types";


export default function useActiveSet(): ShopperTemplatesSetType | null {
    const {setID} = useParams()
    const {shopperTemplatesSets} = useOutletContext<ShopperTemplatesContext>()

    return shopperTemplatesSets.find(set => set.id === parseInt(setID || '0')) || null
}
