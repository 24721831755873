const DevApiUrl = "http://localhost:8000"
const ProdApiUrl = "https://api.liplep.com"
export const ApiUrl = process.env.NODE_ENV === 'development' ? DevApiUrl : ProdApiUrl

const DevBotUsername = 'LipLepTestBot';
const ProdBotUsername = 'LipLepBot'
export const BotUsername = process.env.NODE_ENV === 'development' ? DevBotUsername : ProdBotUsername


export const ThisUrl = process.env.NODE_ENV === 'development' ? 'webapp.ms.dev.payforsay.com' : 'app.liplep.com'

export const LepCosts = 10
export const EnergyCosts = 1

export const MaxEnergy = 100

export const LIP = 'lip'
export const LEP = 'lep'

export const DefaultLang = 'en'
export const AllowedLanguages = ['ru', 'uk', 'en']
