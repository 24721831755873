import React, {useEffect, useState} from "react";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
  MDBTabsContent,
} from "mdb-react-ui-kit";
import {checkEmailExists, createUser, login, sendConfirmEmail} from "../../api/user";
import useMergeState from "../../mergedState";
import {LoginForm, RegisterForm, WaitForConfirmEmail} from "./components";
import {IsConfirmedChecker} from "../../confirmEmailWaiter";
import {ChangePassword} from "../changePassword/components";
import {localise} from "../../api/localise";


export default function LoginRegister ( { lang, onLogin } ) {
    const [fillActive, setFillActive] = useState("tab-login");
    const [formData, setFormData, resetFormData] = useMergeState({
        email: '',
        password: '',
        confirmPassword: '',
        acceptAgreement: false,
        acceptAgreementDatetime: null,
    })
    const [isWaitForConfirmEmail, setIsWaitForConfirmEmail] = useState(false)
    const [isExistsEmailError, setIsExistsEmailError] = useState(false)
    const [isIncorrectCredsError, setIsIncorrectCredsError] = useState(false)
    const [localisedTexts, setLocalisedTexts] = useMergeState({
        loginButton: '',
        registerButton: '',
        forgotPasswordText: '',
        loginSubmitButton: '',
        registerSubmitButton: '',
        notRegisteredText: '',
        loginWithTelegramText: '',
        loginEmailLabel: '',
        loginPasswordLabel: '',
        loginIncorrectEmailOrPasswordText: '',
    })

    useEffect(() => {
        localise('login button', lang).then(
            text => setLocalisedTexts({loginButton: text})
        )
        localise('register button', lang).then(
            text => setLocalisedTexts({registerButton: text})
        )
        localise('forgot password text', lang).then(
            text => setLocalisedTexts({forgotPasswordText: text})
        )
        localise('login submit button', lang).then(
            text => setLocalisedTexts({loginSubmitButton: text})
        )
        localise('register submit button', lang).then(
            text => setLocalisedTexts({registerSubmitButton: text})
        )
        localise('not registered text', lang).then(
            text => setLocalisedTexts({notRegisteredText: text})
        )
        localise('login with telegram text', lang).then(
            text => setLocalisedTexts({loginWithTelegramText: text})
        )
        localise('login email label', lang).then(
            text => setLocalisedTexts({loginEmailLabel: text})
        )
        localise('login password label', lang).then(
            text => setLocalisedTexts({loginPasswordLabel: text})
        )
        localise('login incorrect email or password text', lang).then(
            text => setLocalisedTexts({loginIncorrectEmailOrPasswordText: text})
        )
    }, [lang])


    const handleFillClick = (value) => {
      if (value === fillActive) {
          return;
      }

      setFormData({email: '', password: '', confirmPassword: ''})
      setFillActive(value);
      setIsExistsEmailError(false)
      setIsIncorrectCredsError(false)
      setIsWaitForConfirmEmail(false)
  };

    function onTelegramAuth(user) {
        login(lang, {telegramData: user}).then(
            result => {
                if (result === null) {
                    setIsIncorrectCredsError(true)
                } else {
                    if (onLogin) {
                        onLogin(result)
                      }
                }
            }
        )
  }

    const formOnChange = (field) => {
        return (event) => {
            const newData = {}
            newData[field] = event.target.value;
            setFormData(newData)
            if (field === 'email') {
                setIsExistsEmailError(false)
            }
        }
    }

    const loginFormSubmit = (event) => {
        event.preventDefault();

        login(lang, {
            email: formData.email,
            password: formData.password,
        }).then(
            result => {
                if (result === null) {
                    setIsIncorrectCredsError(true)
                } else {
                    setIsIncorrectCredsError(false)
                    if (onLogin) {
                        resetFormData()
                        onLogin(result)
                    }
                }
            }
        )
    }

    const registerForSubmit = (event) => {
        event.preventDefault();

        if (formData.password.length < 8 || isExistsEmailError || formData.password !== formData.confirmPassword) {
            return
        }

        checkEmailExists(formData.email).then(
            result => {
              if (result.is_exists) {
                  return setIsExistsEmailError(true)
              }
              sendConfirmEmail(formData.email, 'register', lang).then(
                  result => {
                      setIsWaitForConfirmEmail(true)
                      IsConfirmedChecker(result.token, () => {
                          createUser(
                              formData.email, formData.password,
                              null, formData.acceptAgreement,
                              formData.acceptAgreementDatetime
                          ).then(onLogin)
                      })
                  })
            })
    }

    const onChangedPassword = ( { email, password } ) => {
        login(lang, {email, password}).then(
            result => {
                if (result === null){
                    return setIsIncorrectCredsError(true)
                }
                if (onLogin) {
                    onLogin(result)
                }
            })
    }

  return (
    <MDBContainer className={'p-0 w-100'} fluid>
      <MDBCard>
            <MDBCardBody className="p-4">
              <MDBTabs pills fill className="mb-3">
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleFillClick("tab-login")}
                    active={fillActive === "tab-login"}
                  >
                      {localisedTexts.loginButton}
                  </MDBTabsLink>
                </MDBTabsItem>
                <MDBTabsItem>
                  <MDBTabsLink
                    onClick={() => handleFillClick("tab-register")}
                    active={fillActive === "tab-register"}
                  >
                      {localisedTexts.registerButton}
                  </MDBTabsLink>
                </MDBTabsItem>
              </MDBTabs>
              <MDBTabsContent>
                <MDBTabsPane
                  show={fillActive === "tab-login"}
                  aria-labelledby="tab-login"
                >
                  <LoginForm
                      lang={lang}
                      isActive={fillActive === "tab-login"}
                      onTelegramAuth={onTelegramAuth}
                      formOnChange={formOnChange}
                      changeTab={handleFillClick}
                      onSubmit={loginFormSubmit}
                      formData={formData}
                      isIncorrectCredsError={isIncorrectCredsError}
                      localisedTexts={localisedTexts}
                  />
                </MDBTabsPane>
                <MDBTabsPane
                  show={fillActive === "tab-register"}
                  aria-labelledby="tab-register"
                >
                  {isWaitForConfirmEmail ? (
                      <WaitForConfirmEmail/>
                  ) : (
                      <RegisterForm
                          formOnChange={formOnChange}
                          formData={formData}
                          setFormData={setFormData}
                          onSubmit={registerForSubmit}
                          isExistsEmailError={isExistsEmailError}
                          setIsExistsEmailError={setIsExistsEmailError}
                      />
                  )}
                </MDBTabsPane>
                <MDBTabsPane
                  show={fillActive === "tab-forgot-password"}
                  aria-labelledby="tab-forgot-password"
                >
                    <ChangePassword
                        lang={lang}
                        callbackFunc={onChangedPassword}
                        show={fillActive === "tab-forgot-password"}
                    />
                </MDBTabsPane>
              </MDBTabsContent>
            </MDBCardBody>
          </MDBCard>
    </MDBContainer>
  );
}
