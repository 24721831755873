import tinycolor from "tinycolor2";


function setCssParam(el, param, value) {
    el.style = `${el.style.cssText} ${param}: ${value};`
}


function onHover(colorVar, param) {
    return (e) => {
        if (e.type === 'mouseover' && window.mobileCheck()) {
            return;
        }
        const style = getComputedStyle(document.documentElement)
        const tgColor = tinycolor(style.getPropertyValue(colorVar))
        const color = tgColor.isLight() ? tgColor.darken(5).toString() : tgColor.lighten(5).toString()
        setCssParam(e.currentTarget, param, `${color}!important`)
    }

}

function outHover(colorVar, param) {
    return (e) => {
        const style = getComputedStyle(document.documentElement)
        const color = style.getPropertyValue(colorVar)
        setCssParam(e.currentTarget, param, `${color}!important`)
    }

}


export function onHoverButton(colorVar) {
    return onHover(colorVar, 'background-color')

}

export function outHoverButton(colorVar) {
    return outHover(colorVar, 'background-color')

}


export function onHoverText(colorVar) {
    return onHover(colorVar, 'color')

}

export function outHoverText(colorVar) {
    return outHover(colorVar, 'color')

}


export function getHoverButton(colorVar) {
    return {
        onTouchStart: onHoverButton(colorVar),
        onTouchEnd: outHoverButton(colorVar),
        onTouchCancel: outHoverButton(colorVar),
        onMouseOver: onHoverButton(colorVar),
        onMouseOut: outHoverButton(colorVar),
        onBlur: outHoverButton(colorVar),
    }
}


export function getHoverText(colorVar) {
    return {
        onTouchStart: onHoverText(colorVar),
        onTouchEnd: outHoverText(colorVar),
        onTouchCancel: outHoverText(colorVar),
        onMouseOver: onHoverText(colorVar),
        onMouseOut: outHoverText(colorVar),
        onBlur: outHoverText(colorVar),
    }
}
