import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "mdb-react-countdown/dist/css/countdown.min.css"
import "mdb-react-file-upload/dist/css/file-upload.min.css"
import "@fortawesome/fontawesome-free/css/all.min.css";
import {Route, Routes, BrowserRouter} from "react-router-dom";
import {ConfirmEmailPage} from "./confirmEmail";
import {HomePage} from "./pages/home/page";
import TemplatesImagesPage from "./pages/admin/images/page";
import GenerateImagePage from "./pages/admin/generateImage/page";
import ShoppersAdminPage from "./pages/admin/shoppers/page";
import Authorization from "./authorization";
import App from "./App";
import Admin from "./pages/admin/admin";
import AdminHomePage from "./pages/admin/home";
import CompaniesAdminPage from "./pages/admin/companies/page";
import {NavigateToHome, NavigateToLang} from "./notFound";
import mobileCheck from "./mobileCheck";
import TGLoginButtonPage from "./pages/tgLoginButton/page";
import TestDeepLinkPage from "./pages/testDeepLink/page";
import LinkPage from "./pages/admin/link/page";
import PrivacyPolicyPage from "./pages/PrivacyPolicy/page";
import ShopperTemplatesSetsListPage from "./pages/admin/ShopperTemplates/pages/ShopperTemplatesSetsListPage";
import ShopperTemplatesLayout from "./pages/admin/ShopperTemplates/ShopperTemplatesLayout";
import ShopperTemplatesSetPage from "./pages/admin/ShopperTemplates/pages/ShopperTemplatesSetPage";
import CertificatesPage from "./pages/admin/CertificatesPage";
import UseCertificatePage from "./pages/UseCertificatePage";
import OpenMarketPage from "./pages/OpenMarketPage";


window.mobileCheck = mobileCheck


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path={'/:lang/'} element={<App/>}>
                <Route element={<Authorization/>}>
                    <Route index exact element={<HomePage/>}/>
                    <Route path={'admin'} element={<Admin/>}>
                        <Route index exact element={<AdminHomePage/>}/>
                        <Route path={'shoppers'} element={<ShoppersAdminPage/>}/>
                        <Route path={'generateImage'} element={<GenerateImagePage/>}/>
                        <Route path={'images'} element={<TemplatesImagesPage/>} />
                        <Route path={'companies'} element={<CompaniesAdminPage/>}/>
                        <Route path={'link'} element={<LinkPage/>}/>
                        <Route path={'shopperTemplates'} element={<ShopperTemplatesLayout/>}>
                            <Route path={':setID'} element={<ShopperTemplatesSetPage/>}/>
                            <Route path={'sets'} element={<ShopperTemplatesSetsListPage/>}/>
                        </Route>
                        <Route path={'certificates'} element={<CertificatesPage/>}/>
                    </Route>
                </Route>
                <Route path={'useCertificate'} element={<UseCertificatePage/>}/>
                <Route path={'openMarket'} element={<OpenMarketPage/>}/>
                <Route path={'tgLoginButton'} element={<TGLoginButtonPage/>}/>
                <Route path={'confirmEmail'} element={<ConfirmEmailPage/>} />
                <Route path={'*'} element={<NavigateToHome/>}/>
                <Route path={'appPrivacyPolicy'} element={<PrivacyPolicyPage/>}/>
            </Route>
            <Route path={'/testDeepLink'} element={<TestDeepLinkPage/>}/>
            <Route path={'*'} element={<NavigateToLang/>}/>
        </Routes>
    </BrowserRouter>
);
