import './App.scss';
import {Outlet, useParams} from "react-router-dom";
import {useEffect} from "react";
import tinycolor from "tinycolor2";
import {AllowedLanguages} from "./config";
import {NavigateToLang} from "./notFound";

const WebApp = window.Telegram.WebApp;
const scheme = WebApp.themeParams

function App() {
    const params = useParams()

    useEffect(() => {
        const color = tinycolor(scheme.button_color ? scheme.button_color : '#000')
        document.documentElement.style.setProperty(
            '--tg-theme-secondary-button-color',
            color.isLight() ? color.darken(5).toString() : color.lighten(5).toString()
        )
    }, [])


    const context = {
        lang: params.lang,
    }

    if (!AllowedLanguages.includes(params.lang)) {
        return <NavigateToLang/>
    }

    return <Outlet context={context} />
}

export default App;
