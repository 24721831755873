import {useEffect, useState} from "react";
import {getTemplatesPhotos} from "../../../api/shopper";
import {Images} from "./components";
import {MDBContainer} from "mdb-react-ui-kit";


export default function TemplatesImagesPage() {
    const [photos, setPhotos] = useState(false)

    useEffect(() => {
        getTemplatesPhotos().then(
            data => {
                setPhotos(data.photos)
            }
        )
    }, [])

    return (
        <MDBContainer fluid className={'text-center'}>
            <div className={'d-flex align-items-center justify-content-center'} style={{height: '100vh'}}>
                <div>
                    <p>Scroll down to see images</p>
                    <p>
                      <i className='far fa-arrow-alt-circle-down fa-3x'></i>
                    </p>
                </div>
            </div>
            <Images images={photos}/>
        </MDBContainer>
    )
}
