import {GET, POST} from "./base";
import {getHeaders} from "./helpers";



const BASE_PATH = `shoppers`


export async function getMyShoppersList ( token, lang ) {
    return await GET(`${BASE_PATH}/myList`, {
        headers: getHeaders({token, lang})
    })
}


export async function checkScanned (scanID, shopperID, token, lang) {
    return await POST(`${BASE_PATH}/${shopperID}/imitateScan`, {
        scan_id: scanID,
    }, {
        headers: getHeaders({token, lang})
    }, true)
}


export async function scanCheck( receiptData, shopperID, token, lang ) {
    return await POST(`${BASE_PATH}/${shopperID}/scanCheck`, {
        receipt_data: receiptData,
    }, {
        headers: getHeaders({token, lang})
    }, true)
}


export async function scanCheckByCode( code, shopperID, token, lang) {
    return await POST(`${BASE_PATH}/${shopperID}/scanCheckByCode`, {
        code: code,
    }, {
        headers: getHeaders({token, lang})
    }, true)
}


export async function renewEnergy(renewCount, shopperID, token, lang) {
    return await POST(`${BASE_PATH}/${shopperID}/renewEnergy`, {
        renew_count: renewCount,
    }, {
        headers: getHeaders({token, lang})
    }, true)
}


export async function resetDailyScans(shopperID, token, lang) {
    return await POST(`${BASE_PATH}/${shopperID}/resetDailyScans`, {}, {
       headers: getHeaders({token, lang})
    }, true)
}


export async function levelUp(shopperID, token, lang) {
    return await POST(`${BASE_PATH}/${shopperID}/levelUp`, {}, {
        headers: getHeaders({token, lang})
    }, true)
}


export async function finishLevelUp(shopperID, token, lang) {
    return await POST(`${BASE_PATH}/${shopperID}/finishLevelUp`, {}, {
        headers: getHeaders({token, lang})
    }, true)
}


export async function improveAttribute(shopperID, attributeName, token, lang) {
    return await POST(`${BASE_PATH}/${shopperID}/improveAttribute`, {
        attribute_name: attributeName,
    }, {
        headers: getHeaders({token, lang})
    }, true)
}


export async function createShopper(token, lang, ownerID, rarity='common', photo, photoPath, name, level, attributes) {
    const data = new FormData()
    data.append('rarity', rarity)
    if (ownerID) {
        data.append('owner_id', ownerID)
    }

    if (photo) {
        data.append('photo', photo)
    } else if (photoPath) {
        data.append('photo_path', photoPath)
    }

    if (name) {
        data.append('name', name)
    }

    if (level) {
        data.append('level', level)
    }

    if (attributes) {
        Object.keys(attributes).forEach((attributeName) => {
            data.append(attributeName, attributes[attributeName])
        })
    }

    return await POST(`${BASE_PATH}/create`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            ...getHeaders({token, lang}),
        }
    }, true)
}


export async function getTemplatesPhotos() {
    return await GET(`${BASE_PATH}/templates/photos`, )
}


export async function generateShopperImage(rarity, huePercent, token, lang) {
    return await POST(`${BASE_PATH}/generateShopperImage`, {
        rarity: rarity,
        hue_percent: huePercent
    }, {
        headers: getHeaders({token, lang})
    }, true)
}


export async function getShoppersList(withOwner, search, token, lang) {
    const query = new URLSearchParams()
    if (withOwner === true) {
        query.append('with_owner', '1')
    } else if (withOwner === false) {
        query.append('with_owner', '0')
    }

    if (search) {
        query.append('search', search)
    }

    return await GET(`${BASE_PATH}/?${query}`, {
        headers: getHeaders({token, lang})
    })
}


export async function generateAttributes(rarity, token, lang) {
    return await POST(`${BASE_PATH}/generateAttributes`, {rarity: rarity}, {
        headers: getHeaders({token, lang}),
    })
}


export async function generateShopperName(token, lang) {
    return await POST(`${BASE_PATH}/generateName`, {}, {
        headers: getHeaders({token, lang})
    })
}


export async function updateShopperOwner(shopperID, ownerID, token, lang) {
    return await POST(`${BASE_PATH}/${shopperID}/updateOwner`, {
        owner_id: ownerID,
    }, {
        headers: getHeaders({token, lang})
    }, true)
}
