import axios from 'axios'
import {ApiUrl} from '../config';

const instance = axios.create({
    baseURL: ApiUrl,
    headers: {
      "content-type": "application/json",
      "Accept": "application/json"
    }
})

export default instance;
