import useLoadShopperTemplatesSets from "./hooks/useLoadShopperTemplatesSets";
import {Outlet, useOutletContext} from "react-router-dom";
import {AppContext} from "../../../types";
import {ShopperTemplatesContext} from "./types";
import {
    UpdateShopperTemplatesSetDataType
} from "../../../api/ShopperTemplates/ShopperTemplatesSets/types";


export default function ShopperTemplatesLayout() {
    const [shopperTemplatesSets, setShopperTemplatesSets] = useLoadShopperTemplatesSets()
    const outletContext = useOutletContext<AppContext>()

    const context: ShopperTemplatesContext = {
        ...outletContext,
        shopperTemplatesSets,
        setShopperTemplatesSets,
        updateTemplatesSet(setID: number, data: UpdateShopperTemplatesSetDataType) {
            setShopperTemplatesSets(prevState => (
                prevState.map(set => {
                    if (set.id !== setID) {
                        return set
                    } else {
                        return {...set, ...data}
                    }
                })
            ))
        }
    }

    return (
        <Outlet context={context}/>
    )
}
