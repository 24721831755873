import {AxiosInstance} from "axios";
import {
    CreateShopperTemplatesSetData,
    DeletePartDataType,
    PartAdded,
    PartDeleted, PartsDeleted, ShopperTemplatesSetDeleted, ShopperTemplatesSetPartsType,
    ShopperTemplatesSetType,
    UpdateShopperTemplatesSetDataType
} from "./types";


export default function ShopperTemplatesSetsApiModule(instance: AxiosInstance, prefix: string) {
    const getUrl = (path: string | number) => prefix + 'sets/' + path

    return {
        getList() {
            return instance.get<ShopperTemplatesSetType[]>(getUrl(''))
        },
        createSet(data: CreateShopperTemplatesSetData) {
            return instance.post<ShopperTemplatesSetType>(getUrl('create'), data)
        },
        copySet(setID: number) {
            return instance.post<ShopperTemplatesSetType>(getUrl(setID + '/copy'))
        },
        updateSet(setID: number, data: UpdateShopperTemplatesSetDataType) {
            return instance.patch<ShopperTemplatesSetType>(getUrl(setID + '/'), data)
        },
        deleteSet(setID: number) {
            return instance.delete<ShopperTemplatesSetDeleted>(getUrl(setID + '/'))
        },
        deletePart(setID: number, data: DeletePartDataType) {
            return instance.post<PartDeleted>(getUrl(setID + '/deletePart'), data)
        },
        deleteParts(setID: number, partType?: keyof ShopperTemplatesSetPartsType | 'all') {
            return instance.post<PartsDeleted>(getUrl(setID + '/deleteParts'), {
                part_type: partType
            })
        },
        addPart(setID: number, part: File, partType: string, partName: string) {
            const data = new FormData()

            data.append('part', part)
            data.append('part_type', partType)
            data.append('part_name', partName)

            return instance.post<PartAdded>(getUrl(setID + '/addPart'), data, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
        },
        importFromArchive(setID: number, archive: File) {
            const data = new FormData()
            data.append('archive', archive)
            return instance.post<ShopperTemplatesSetType>(getUrl(setID + '/importFromArchive'), data)
        },
    }
}
