import {GET, POST} from "./base";
import {getHeaders} from "./helpers";


export async function getUsersList(token, lang, search) {
    return await GET(`users/?search=${search}`, {
        headers: getHeaders({token: token, lang: lang})
    }, true)
}


export async function login(lang, {email, password, telegramData}) {
    return await POST('users/login', {
        email: email,
        password: password,
        telegram_data: telegramData
    }, {
        headers: getHeaders({lang: lang})
    })
}


export async function sendConfirmEmail(email, purpose, lang) {
    return await POST('users/sendConfirmEmail', {
        email: email,
        from_app: 'web',
        purpose: purpose,
    }, {
        headers: getHeaders({lang: lang})
    })
}


export async function confirmEmail(token, lang) {
    return await POST('users/confirmEmail', {}, {
        headers: getHeaders({token, lang})
    })
}


export async function getMe(token, lang, returnResponse) {
    return await GET('users/me', {
        headers: getHeaders({token, lang})
    }, returnResponse)
}


export async function checkEmailExists(email, lang) {
    return await GET(`users/checkEmail/${email}`, {
        headers: getHeaders({lang: lang})
    })
}


export async function checkChatIDExists(chat_id, lang) {
    return await GET(`users/checkChatID/${chat_id}`, {
        headers: getHeaders({lang: lang})
    })
}


export async function checkIsTGConnected(email, lang) {
    return await GET(`users/checkIsTGConnected/${email}`, {
        headers: getHeaders({lang: lang})
    })
}


export async function checkIsConfirmed(token) {
    return await GET('users/checkIsEmailConfirmed', {
        headers: getHeaders({token})
    })
}


export async function createUser(email, password, telegramData, isAcceptAgreement, acceptAgreementDatetime) {
    return await POST('users/create', {
        from_app: 'web',
        email: email,
        password: password,
        telegram_data: telegramData,
        is_accept_agreement: isAcceptAgreement,
        accept_agreement_datetime: acceptAgreementDatetime,
    })
}


export async function setTelegram(telegramData, token, lang) {
    return await POST('users/setTelegram', telegramData, {
        headers: getHeaders({token: token, lang: lang})
    })
}


export async function setEmail(email, password, token, lang) {
    return await POST('users/setEmail', {
        email: email,
        password: password,
    }, {
        headers: getHeaders({token, lang})
    })
}


export async function changePassword(email, telegramData, password, lang) {
    return await POST('users/changePassword', {
        email: email,
        telegram_data: telegramData,
        password: password,
    }, {
        headers: getHeaders({lang: lang})
    })
}


export async function changeEmail(email, telegramData, password, lang) {
    return await POST('users/changeEmail', {
        email: email,
        telegram_data: telegramData,
        password: password,
    }, {
        headers: getHeaders({lang: lang})
    })
}


export async function getLink(token, linkBase, scopes = []) {
    const params = new URLSearchParams()
    params.append('link_base', linkBase)
    scopes.forEach(scope => {
        params.append('scopes', scope)
    })

    return await GET('users/link', {
        params: params,
        headers: getHeaders({token})
    })
}
