import {useLocation, useParams} from "react-router-dom";
import {useEffect, useState} from "react";

import {confirmEmail} from "./api/user";


export function ConfirmEmailPage(  ) {
    const params = useParams()

    const [isConfirmed, setIsConfirmed] = useState(false)
    const location = useLocation()

    const query = new URLSearchParams(location.search)

    const token = query.get("confirm_token")
    const lang = params.lang
    const from_app = query.get("from_app")
    const bot_username = query.get("bot_username")


    useEffect(() => {
        confirmEmail(token, lang).then((result) => {
            if (result.confirmed === true) {
                setIsConfirmed(true)
                if (from_app === 'telegram') {
                    window.location.href = `https://t.me/${bot_username}`
                }
                setTimeout(() => window.close(), 5000)
            }
        })
    }, [])

    return <div>
        {isConfirmed ? 'confirmed' : 'not confirmed'}
    </div>
}
