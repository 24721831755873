import {MDBBtn, MDBBtnGroup} from "mdb-react-ui-kit";
import {SetTemplates} from "../../../../../../../api/ShopperTemplates/ShopperTemplatesSets/types";

type RarityType = keyof SetTemplates


const RARITIES: RarityType[] = [
    'common',
    'uncommon',
    'rare',
    'epic',
    'legendary',
]


interface TemplatesFiltersProps {
    activeRarity: RarityType
    setActiveRarity: (value: RarityType) => void
}


export default function TemplatesFilters({activeRarity, setActiveRarity}: TemplatesFiltersProps) {

    const checked = (isChecked: boolean) => {
        return isChecked ? 'checked': ''
    }

    const getOnClick = (rarity: RarityType) => () => {
        setActiveRarity(rarity)
    }

    return (
        <div className={'d-flex justify-content-center w-100'}>
            <MDBBtnGroup>
                {RARITIES.map((rarity) => {
                    return (
                        <MDBBtn
                            className={checked(rarity === activeRarity)}
                            onClick={getOnClick(rarity)}
                        >
                            {rarity.charAt(0).toUpperCase() + rarity.slice(1)}
                        </MDBBtn>
                    )
                })}
            </MDBBtnGroup>
        </div>
    )
}
