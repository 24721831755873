import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardImage,
    MDBCardTitle,
    MDBLazyLoading
} from "mdb-react-ui-kit";
import api from "../../../../../../../api";
import LoadingImage from "../../../../../../../Loading.gif";
import useActiveSet from "../../hooks/useActiveShopperTemplatesSet";
import {ShopperTemplatesSetPartsType} from "../../../../../../../api/ShopperTemplates/ShopperTemplatesSets/types";
import {useOutletContext} from "react-router-dom";
import {ShopperTemplatesContext} from "../../../../types";


interface PartCardProps {
    part: string
    partType: keyof ShopperTemplatesSetPartsType
    idx: number

}


export default function PartCard({part, partType, idx}: PartCardProps) {
    const {updateTemplatesSet} = useOutletContext<ShopperTemplatesContext>()
    const activeSet = useActiveSet()

    const partSplit = part.split("/")
    const name = partSplit[partSplit.length - 1]

    const src = api.getFileUrl(part)

    const onClick = () => {
        if (!activeSet || !activeSet.parts) return

        const parts = activeSet.parts as ShopperTemplatesSetPartsType

        api.shopperTemplates.sets.deletePart(activeSet.id, {
            part: part,
            part_type: partType
        }).then(response => {
            const data = response.data

            updateTemplatesSet(activeSet.id, {
                ...activeSet,
                parts: {
                    ...parts,
                    [data.deleted_part_type]: parts[data.deleted_part_type].filter(el => el !== data.deleted_part)
                }
            })
        })
    }

    return (
        <MDBCard className={'h-100'}>
            {idx > 4 ? (
                <MDBLazyLoading
                  lazySrc={src}
                  lazyPlaceholder={LoadingImage}
                  alt={name}
                  className={'img-fluid'}
                />
            ) : (
                <MDBCardImage
                    src={src}
                    alt={name}
                    className={'img-fluid'}
                />
            )}
            <MDBCardBody>
                <MDBCardTitle>
                    {name}
                </MDBCardTitle>
            </MDBCardBody>
            <MDBCardFooter>
                <MDBBtn className={'btn-danger'} onClick={onClick}>
                    Delete
                </MDBBtn>
            </MDBCardFooter>
        </MDBCard>
    )
}
