import {POST} from "./base";
import {getHeaders} from "./helpers";


export async function buyLep(lep, token, lang) {
    return await POST('finances/buyLep', {
        lep_sum: lep,
    }, {
        headers: getHeaders({token, lang})
    }, true)
}
