import {Outlet, useParams, useSearchParams} from "react-router-dom";
import {useMDBStorage} from "mdb-react-storage-managament";
import {useEffect, useState} from "react";
import LoginRegister from "./pages/loginRegister/page";
import {getMe} from "./api/user";
import {MDBContainer} from "mdb-react-ui-kit";
import api from "./api";


export default function Authorization( ) {
    const params = useParams()
    const [searchParams, setSearchParams] = useSearchParams()

    const [tokenInStorage, {setItem, removeItem}] = useMDBStorage('token', null)
    const [token, setTokenState] = useState(tokenInStorage)
    const [user, setUser] = useState(null)

    const queryToken = searchParams.get("token")

    const setToken = (token) => {
        if (token === undefined){
            token = null
        }
        setItem(token)
        setTokenState(token)
    }

    const removeToken = () => {
        removeItem()
        setTokenState(null)
    }

    const onLogin = (result) => {
        if (result) {
            setToken(result.token)
        }
    }

    useEffect(() => {
        if (queryToken) {
            setToken(queryToken)
            setSearchParams(Object.fromEntries(Object.entries(searchParams).filter(([key]) => key !== "token")))
        }
    }, [queryToken])

    useEffect(() => {
        if (!token) {
            return
        }

        api.instance.defaults.headers.common["Authorization"] = "bearer " + token
        api.instance.defaults.headers.common["Accept-Language"] = params.lang

        getMe(token, params.lang, true).then(
            response => {
                if (response.status === 200) {
                    setUser(response.data)
                } else {
                    setUser(null)
                    removeToken()
                }
            })
    }, [token, params.lang])

    const context = {
        token: token,
        lang: params.lang,
        setToken: setToken,
        removeToken: removeToken,
        user: user,
        setUser: setUser,
    }

    if (!token) {
        return (
            <MDBContainer fluid className={'d-flex justify-content-center align-items-center'} style={{minHeight: '100vh'}}>
                <div style={{maxWidth: '450px'}}>
                    <LoginRegister
                        lang={params.lang}
                        onLogin={onLogin}
                    />
                </div>
            </MDBContainer>
        )
    }

    if (!user) {
        return null
    }

    return <Outlet context={context}/>
}
