import {Certificate, CertificateRarity, CreateCertificateData} from "../../../../api/Certificates/types";
import {useEffect, useState} from "react";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBIcon,
    MDBInput,
    MDBSelect
} from "mdb-react-ui-kit";
import {generateRandomString} from "../../../../helpers";
import ShopperTemplatesSetSelect from "./ShopperTemplatesSetSelect";

interface CertificateCardProps {
    createCertificate: (data: CreateCertificateData) => void
    createHeader: string
    createButton: string
    typeLabel: string
    codeLabel: string
    allowedUsagesLabel: string
    allowedUsagesPerUserLabel: string
    shopperTypeText: string
    shopper_from_setTypeText: string
    promo_codeTypeText: string
    shopperTemplatesSetSelectLabel: string
    shopperTemplatesSetItemText: string
    privateText: string
    rarityLabel: string
    levelLabel: string
    lipLabel: string
    lepLabel: string
}


export default function CreateCertificateCard({...props}: CertificateCardProps) {
    const [code, _setCode] = useState('')
    const [type, setType] = useState<Certificate["type"]>('shopper_from_set')

    const [allowedUsagesCount, setAllowedUsagesCount] = useState<number>(0)
    const [allowedUsagesPerUserCount, setAllowedUsagesPerUserCount] = useState<number>(0)

    const [shopperTemplatesSetID, setShopperTemplatesSetID] = useState<number | null>(null)
    const [rarity, setRarity] = useState<CertificateRarity>('common')
    const [level, setLevel] = useState(0)

    const [lip, setLip] = useState(0)
    const [lep, setLep] = useState(0)

    const setCode = (code: string) => {
        _setCode(code.toUpperCase())
    }


    useEffect(() => {
        setCode(generateRandomString(8))
    }, [])

    return (
        <MDBCard className={'h-100'}>
            <MDBCardHeader>
                {props.createHeader}
            </MDBCardHeader>
            <MDBCardBody>
                <div className={'d-flex align-items-center w-100'}>
                    <MDBInput
                        label={props.codeLabel}
                        value={code}
                        onChange={(event) => setCode(event.target.value)}
                        wrapperClass={'flex-1'}
                    />
                    <a href={'#'} className={'ms-2'} onClick={(event) => {
                        event.preventDefault()
                        setCode(generateRandomString(8))
                    }}>
                        <MDBIcon icon={'fa fa-random'} size={'lg'}/>
                    </a>
                </div>

                <MDBInput
                    type={'number'}
                    label={props.allowedUsagesLabel}
                    value={allowedUsagesCount}
                    onChange={(event) => setAllowedUsagesCount(parseInt(event.target.value))}
                    wrapperClass={'mt-2'}
                />

                <MDBInput
                    type={'number'}
                    label={props.allowedUsagesPerUserLabel}
                    value={allowedUsagesPerUserCount}
                    onChange={(event) => setAllowedUsagesPerUserCount(parseInt(event.target.value))}
                    wrapperClass={'mt-2'}
                />

                <MDBSelect
                    label={props.typeLabel}
                    data={[
                        {
                            text: props.promo_codeTypeText,
                            value: 'promo_code',
                            defaultSelected: type === 'promo_code',
                        },
                        {
                            text: props.shopper_from_setTypeText,
                            value: 'shopper_from_set',
                            defaultSelected: type === 'shopper_from_set',
                        },
                        {
                            text: props.shopperTypeText,
                            value: 'shopper',
                            defaultSelected: type === 'shopper',
                            disabled: true,
                        },
                    ]}
                    onValueChange={(data) => {
                        // @ts-ignore
                        setType(data.value)
                    }}
                    className={'mt-3'}
                />

                {type === 'shopper_from_set' ? (<>
                    <ShopperTemplatesSetSelect
                        label={props.shopperTemplatesSetSelectLabel}
                        chosenSetID={shopperTemplatesSetID}
                        setChosenSetID={setShopperTemplatesSetID}
                        itemText={props.shopperTemplatesSetItemText}
                        privateText={props.privateText}
                    />

                    <MDBSelect
                        data={['common', 'uncommon', 'rare', 'epic', 'legendary'].map(el => ({
                            text: el, value: el, defaultSelected: rarity === el,
                        }))}
                        onValueChange={(data) => {
                            setRarity((data as {value: CertificateRarity}).value)
                        }}
                        className={'mt-2'}
                        label={props.rarityLabel}
                    />

                    <MDBInput
                        type={'number'}
                        label={props.levelLabel}
                        value={level}
                        onChange={(event) => setLevel(prevState => parseInt(event.target.value) ?? prevState)}
                        wrapperClass={'mt-2'}
                    />
                </>) : type === 'promo_code' ? <div/> : (
                    <h3 className={'text-center mt-2'}>SOON</h3>
                )}

                <MDBInput
                    type={'number'}
                    label={props.lipLabel}
                    value={lip}
                    onChange={(event) => setLip(prevState => parseInt(event.target.value) ?? prevState)}
                    wrapperClass={'mt-2'}
                />

                <MDBInput
                    type={'number'}
                    label={props.lepLabel}
                    value={lep}
                    onChange={(event) => setLep(prevState => parseInt(event.target.value) ?? prevState)}
                    wrapperClass={'mt-2'}
                />

            </MDBCardBody>
            <MDBCardFooter>
                <MDBBtn disabled={!code || !type} onClick={() => {
                    setCode(generateRandomString(8))

                    if (type !== 'shopper') {
                        const data: CreateCertificateData = {
                            code: code,
                            type: type,
                            allowed_usages_count: allowedUsagesCount || null,
                            allowed_usages_per_user_count: allowedUsagesPerUserCount || null,
                            lip: lip || null,
                            lep: lep || null,
                        }

                        if (type === 'shopper_from_set') {
                            data["shopper_templates_set_id"] = shopperTemplatesSetID
                            data["rarity"] = rarity
                            data["level"] = level
                        }

                        props.createCertificate(data)
                    }
                }}>
                    {props.createHeader}
                </MDBBtn>
            </MDBCardFooter>
        </MDBCard>
    )
}
