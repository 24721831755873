import {
    MDBBadge,
    MDBBtn,
    MDBCardImage,
    MDBCol,
    MDBContainer,
    MDBIcon, MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader, MDBModalTitle,
    MDBNavbar, MDBPopconfirm, MDBPopconfirmMessage,
    MDBProgress,
    MDBProgressBar, MDBRange,
    MDBRow, MDBSelect,
    MDBTooltip
} from "mdb-react-ui-kit";
import {getHoverText} from "../../hover";
import {ApiUrl, EnergyCosts, LepCosts, LIP} from "../../config";
import {TgButton, TgLink} from "../../tgTheme";
import {useEffect, useRef, useState} from "react";
import useMergeState from "../../mergedState";
import {localise} from "../../api/localise";
import EditableText from "../../editableText";
import {MDBCountdown, MDBCountdownUnit} from "mdb-react-countdown";
import {useZxing} from "react-zxing";
import {useTG} from "../../hooks";


function BaseBuyModal(
    {
        titleVariable, buyCostsVariable,
        cancelButtonVariable, buyButtonVariable,
        lang, price, show, setShow, balance, buy,
        currency,
        max=null,
    }
) {
    const [localisedTexts, setLocalisedTexts] = useMergeState({
        title: '',
        buyCostsText: '',
        cancelButton: '',
        buyButton: '',
    })
    const [buyCount, _setBuyCount] = useState(1)
    const [isInputActive, setIsInputActive] = useState(false)

    const costs = buyCount * price


    const maxCanBuy = Math.floor(balance/price)


    if (max !== 0 && !max) {
        max = maxCanBuy
    }

    const maxValue = max < maxCanBuy ? max : (maxCanBuy > 0 ? maxCanBuy : 1)

    const setBuyCount = (value) => {
        _setBuyCount(parseInt(value))
    }

    if (maxValue !== 0 && buyCount > maxValue) {
        setBuyCount(maxValue)
    }

    if (buyCount < 1) {
        setBuyCount(1)
    }


    useEffect(() => {
        localise(titleVariable, lang).then(
            text => setLocalisedTexts({title: text})
        )
        localise(cancelButtonVariable, lang).then(
            text => setLocalisedTexts({cancelButton: text})
        )
        localise(buyButtonVariable, lang).then(
            text => setLocalisedTexts({buyButton: text})
        )
    }, [lang])

    useEffect(() => {
        localise(buyCostsVariable, lang).then(
            text => setLocalisedTexts({buyCostsText: text})
        )
    }, [buyCostsVariable, lang])

    return (
        <MDBModal show={show} setShow={setShow} className={'tg-modal'}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>
                        <h4 className={'w-100 text-center'}>{localisedTexts.title}</h4>
                    </MDBModalHeader>
                    <MDBModalBody className={'text-center'}>
                        <MDBRow className={'d-flex justify-content-center'}>
                            <MDBCol size={3}>
                                <TgButton
                                    onClick={() => {
                                        setBuyCount(buyCount-1)
                                    }}
                                    disabled={buyCount === 1}
                                >
                                    <MDBIcon fas icon="minus" size={'xl'} />
                                </TgButton>
                            </MDBCol>
                            <MDBCol size={5} className={'p-0'}>
                                <EditableText
                                    type={'number'}
                                    outline={false}
                                    editButton={false}
                                    autoFocus={true}
                                    isEdit={isInputActive}
                                    setIsEdit={setIsInputActive}
                                    text={buyCount}
                                    saveValue={setBuyCount}
                                />
                                {maxValue > 1 && (
                                    <MDBRange
                                        disableTooltip={true}
                                        value={buyCount}
                                        min={1}
                                        max={maxValue}
                                        color={'danger'}
                                        onChange={(event) => setBuyCount(event.target.value)}
                                    />
                                )}
                            </MDBCol>
                            <MDBCol size={3}>
                                <TgButton
                                    onClick={() => {
                                        setBuyCount(buyCount+1)
                                    }}
                                    disabled={buyCount+1>maxValue}
                                >
                                    <MDBIcon fas icon="plus" size={'xl'} />
                                </TgButton>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size={12} className={'text-center'}>
                                {localisedTexts.buyCostsText}
                                <span className={'ms-1'}>{costs}</span>
                                <span className={'ms-1'}>{currency}</span>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                    <MDBModalFooter className={'d-flex justify-content-center'}>
                        <MDBRow className={'w-100'}>
                            <MDBCol size={6} className={'pe-1'}>
                                <MDBBtn
                                    className={'w-100 text-center p-2'}
                                    color={'danger'} onClick={() => setShow(false)}
                                >
                                    {localisedTexts.cancelButton}
                                </MDBBtn>
                            </MDBCol>
                            <MDBCol size={6} className={'ps-1'}>
                                <TgButton
                                    className={'w-100 text-center p-2'}
                                    onClick={() => {
                                        buy(buyCount)
                                    }}
                                    disabled={costs>balance || max === 0}
                                >
                                    {localisedTexts.buyButton}
                                </TgButton>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}


export function BuyLepModal( { lang, lip, show, setShow, buyLep } ) {
    return (
        <BaseBuyModal
            titleVariable={'buy lep title'}
            buyCostsVariable={'buy lep costs text'}
            cancelButtonVariable={'buy lep cancel button'}
            buyButtonVariable={'buy lep buy button'}
            lang={lang} price={LepCosts}
            show={show} setShow={setShow}
            balance={lip} buy={buyLep}
            currency={LIP}
        />
    )
}


export function RenewEnergyModal( { lang, lip, show, setShow, renewEnergy, max } ) {
    return (
        <BaseBuyModal
            titleVariable={'renew energy title'}
            buyCostsVariable={'renew energy costs text'}
            cancelButtonVariable={'renew energy cancel button'}
            buyButtonVariable={'renew energy buy button'}
            lang={lang} price={EnergyCosts}
            show={show} setShow={setShow}
            balance={lip} buy={renewEnergy}
            max={max} currency={LIP}
        />
    )
}


export function NavBar(
    {
        imageUrl, fullName,
        openInBrowserButton,
        lipBalanceText,
        lepBalanceText,
        removeToken,
        setShowBuyLep,
        token,
        showLogout=true,
    } ) {
    const {isTG, WebApp} = useTG()

    return (
        <MDBNavbar className={'tg-bg mb-4 pt-0'}>
            <MDBContainer fluid className={'p-1 w-100'}>
                {isTG && (
                    <MDBRow className={'w-100 m-0'}>
                        <MDBCol size={12} className={'d-flex justify-content-center align-items-center'}>
                            <div>
                                <TgLink onClick={() => WebApp.openLink(`${window.location.href}?token=${token}`)}>
                                    {openInBrowserButton}
                                </TgLink>
                            </div>
                        </MDBCol>
                    </MDBRow>
                )}
                <MDBRow className={'w-100 m-0'}>
                    <MDBCol
                        size={4} md={3} lg={2}
                        className={
                        'ps-1 pe-0 d-flex rfs-3 align-items-center justify-content-start'
                    }
                    >
                        <img
                            src={imageUrl} alt={fullName}
                            className='rounded-circle float-left me-2'
                            width={'30%'}
                        />
                        <span className={'me-1'}>{fullName}</span>
                    </MDBCol>
                    <MDBCol size={1} className={'d-flex justify-content-center ps-0 align-items-center'}>
                        {showLogout && (
                            <TgLink onClick={removeToken}>
                                <MDBIcon icon={'fa fa-sign-out'} size={'xl'}/>
                            </TgLink>
                        )}
                    </MDBCol>
                    <MDBCol size={1} md={5} lg={5}></MDBCol>
                    <MDBCol size={6} md={4} className={'d-flex align-items-center p-0'}>
                        <div className={'d-flex justify-content-end w-100'}>
                            <span className={'me-2'}>
                                {lipBalanceText}
                                <TgLink className={'ms-1'}>
                                    <MDBIcon fas icon="plus" />
                                </TgLink>
                            </span>
                            <span className={'me-2'}>
                                {lepBalanceText}
                                <TgLink className={'ms-1'} onClick={() => setShowBuyLep(true)}>
                                    <MDBIcon fas icon="plus" />
                                </TgLink>
                            </span>
                        </div>
                    </MDBCol>
                </MDBRow>
          </MDBContainer>
    </MDBNavbar>
  );
}


export function Image( {shopper} ) {
    if (!shopper.photo_path) {
        return null
    }
    return (
        <MDBCardImage
            src={`${ApiUrl}/${shopper.photo_path}`}
            className={'w-100'}
            position='top' alt='shopper image'
        />
    )
}


const RarityColors = {
    common: 'info',
    uncommon: 'primary',
    rare: 'warning',
    epic: 'secondary',
    legendary: 'danger',
}


export function Title( { isAdmin, shoppers, activeShopperIDX, setActiveShopperIDX, createShopper, createShopperButton } ) {
    return (
        <MDBRow className={'text-center w-100'}>
            <MDBCol size={1}></MDBCol>
            <MDBCol size={10}>
                <ShoppersSelect
                    isAdmin={isAdmin}
                    shoppers={shoppers}
                    activeShopperIDX={activeShopperIDX}
                    setActiveShopperIDX={setActiveShopperIDX}
                    createShopper={createShopper}
                    createShopperButton={createShopperButton}
                />
            </MDBCol>
            <MDBCol size={1}></MDBCol>
        </MDBRow>
    )
}

export function LevelInfo( { rarity, localisedTexts, levelUp, finishLevelUp, levelUpFinishDateTime } ) {
    const countdown = new Date(`${levelUpFinishDateTime}Z`)
    const isUpgrading = countdown > new Date()

    return (
        <>
            <MDBRow className={'mt-2 w-100 p-0'}>
                <MDBCol size={5} className={'d-flex justify-content-start ps-4 pe-0'}>
                    <div className={'d-flex align-items-center'}>
                        {localisedTexts.levelText}
                        {isUpgrading && (
                            <MDBTooltip
                                tag={'a'}
                                wrapperProps={{
                                    href: '#',
                                    ...getHoverText('--tg-theme-link-color'),
                                }}
                                wrapperClass={'tg-link no-underline'}
                                title={
                                    <div style={{whiteSpace: 'pre'}}>
                                        {localisedTexts.levelUpgradingTooltip}
                                    </div>
                                }
                            >
                                <MDBIcon fas icon="plus" size={'sm'}/>
                                <span>1</span>
                            </MDBTooltip>
                        )}
                        <MDBPopconfirm
                            className={'d-flex justify-content-center'}
                            btnClassName={'ms-2 tg-button-link pop-confirm-btn py-1 px-0'}
                            btnChildren={<MDBIcon fas icon="plus" size={'xl'} />}
                            cancelBtnText={localisedTexts.levelUpCancelButton}
                            cancelBtnClasses={'tg-button'}
                            confirmBtnClasses={'tg-button'}
                            confirmBtnText={localisedTexts.levelUpConfirmButton}
                            onConfirm={levelUp}
                        >
                            <MDBPopconfirmMessage>
                                {localisedTexts.levelUpTooltip}
                            </MDBPopconfirmMessage>
                        </MDBPopconfirm>
                    </div>
                </MDBCol>
                <MDBCol size={3} className={'d-flex align-items-center ps-0'}>
                    <h4 className={'m-0'}>
                        <MDBBadge
                            className={'ms-2'} pill
                            color={RarityColors[rarity]}
                        >
                            {rarity}
                        </MDBBadge>
                    </h4>
                </MDBCol>
                <MDBCol size={4} className={'d-flex justify-content-end'}>
                    {localisedTexts.pointsText}
                </MDBCol>
            </MDBRow>
            <MDBRow className={'ps-0 w-100 mt-2'}>
                <MDBCol size={8} className={'d-flex justify-content-start ps-4'}>
                    {isUpgrading && (
                        <MDBRow>
                            <MDBCol size={12} style={{fontSize: '.8rem'}} className={'pe-0'}>
                                <div className={'d-inline-block'}>
                                    {localisedTexts.timeBeforeFinishText}
                                </div>
                                <div className={'d-inline-block ms-2'}>
                                    <MDBCountdown
                                        id={countdown.toISOString()}
                                        key={countdown.toISOString()}
                                        className={'w-100 justify-content-center d-flex m-0'}
                                        countdown={countdown}
                                        labelPosition={'horizontal'}
                                        style={{fontSize: '.8rem'}}
                                    >
                                        <MDBCountdownUnit type={'hours'} label={localisedTexts.hoursText} className={'me-1'}/>
                                        <MDBCountdownUnit type={'minutes'} label={localisedTexts.minutesText} className={'me-1'}/>
                                        <MDBCountdownUnit type={'seconds'} label={localisedTexts.secondsText} className={'me-1'}/>
                                    </MDBCountdown>
                                </div>
                                <div className={'d-inline-block'}>
                                    <MDBPopconfirm
                                        className={'d-flex justify-content-center'}
                                        btnClassName={'ms-2 tg-button-link pop-confirm-btn p-0'}
                                        btnChildren={<MDBIcon icon="fa-solid fa-forward-fast" />}
                                        cancelBtnText={localisedTexts.cancelFinishLevelUpButton}
                                        cancelBtnClasses={'tg-button'}
                                        confirmBtnClasses={'tg-button'}
                                        confirmBtnText={localisedTexts.finishLevelUpButton}
                                        onConfirm={finishLevelUp}
                                    >
                                        <MDBPopconfirmMessage>
                                            {localisedTexts.finishLevelUpMessage}
                                        </MDBPopconfirmMessage>
                                    </MDBPopconfirm>
                                    <TgLink>

                                    </TgLink>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    )}
                </MDBCol>
                <MDBCol size={4}></MDBCol>
            </MDBRow>
        </>
    )
}


export function EnergyBar( { energy, localisedTexts, setShowRenewEnergy } ) {
    return (
        <MDBRow className={'mt-2 ms-0 me-1 w-100 p-0'}>
            <MDBCol size={1} className={'tg-link-color'}>
                    <MDBIcon icon={'fa fa-bolt'}/>
                </MDBCol>
            <MDBCol size={9} className={'m-auto px-1'}>
                 <MDBProgress height={15} className={'rounded'}>
                    <MDBProgressBar bgColor={'danger'} className={'rounded-start'}  width={
                        energy < 30 ? energy : 30
                    }/>
                    <MDBProgressBar bgColor={'warning'} width={
                        energy > 30 ? (energy < 60 ? energy - 30 : 30) : 0
                    }/>
                    <MDBProgressBar bgColor={'success'} className={'rounded-end'} width={
                        energy > 60 ? energy - 60 : 0
                    }/>
                </MDBProgress>
            </MDBCol>
            <MDBCol size={1} className={'px-0 text-center'}>
                {energy}
            </MDBCol>
            <MDBCol size={1} className={'px-0'}>
                <MDBTooltip
                    tag={'a'}
                    wrapperProps={{
                        href: '#',
                        onClick: () => setShowRenewEnergy(true),
                        className: 'tg-link',
                        ...getHoverText('--tg-theme-link-color') ,
                    }}
                    title={<p style={{whiteSpace: 'pre'}}>{localisedTexts.renewEnergyTooltip}</p>}
                >
                    <MDBIcon icon="fa-thin fa-kit-medical" />
                </MDBTooltip>
            </MDBCol>
        </MDBRow>
    )
}


function ParamBar( { paramName, shopper, localisedTexts, improveAttribute } ) {
    return (
        <MDBRow className={'mt-2 w-100'}>
            <MDBCol size={5} className={'pe-0'}>
                {localisedTexts[`${paramName}Text`]}
            </MDBCol>
            <MDBCol size={5} className={'m-auto ps-0 pe-0'}>
                <MDBProgress className={'rounded'} height={15}>
                    <MDBProgressBar className={'rounded-start tg-button-bg'} width={shopper[paramName]}/>
                </MDBProgress>
            </MDBCol>
            <MDBCol size={1} className={'ps-1 pe-0 text-center'}>
                {shopper[paramName]}
            </MDBCol>
            <MDBCol size={1} className={'px-0 text-center'}>
                <MDBTooltip
                    tag={'button'}
                    wrapperProps={{
                        className: 'ms-2 tg-button-link',
                        onClick: improveAttribute,
                        ...getHoverText('--tg-theme-link-color'),
                    }}
                    title={
                        <p style={{whiteSpace: 'pre'}}>
                            {localisedTexts[`${paramName}ImproveTooltip`]}
                        </p>
                    }
                >
                    <MDBIcon fas icon="plus" size={'xl'} />
                </MDBTooltip>
            </MDBCol>
        </MDBRow>
    )
}


export function Params( { shopper, localisedTexts, improveAttribute } ) {
    return ['efficiency', 'endurance', 'lucky', 'intuition'].map(
        paramName => {
            return (
                <ParamBar
                    key={paramName}
                    paramName={paramName}
                    shopper={shopper}
                    localisedTexts={localisedTexts}
                    improveAttribute={() => improveAttribute(paramName)}
                />
            )
        }
    )
}


export function TestScanButton( { scansLeft, buttonText, onClick, className='' } ) {
    return (
        <TgButton
            onClick={onClick}
            className={`w-100 ${className}`}
            disabled={scansLeft<1}>
            {buttonText}
        </TgButton>
    )
}


export function ScansLeft( { isAdmin, scansLeftText, resetDailyScans} ) {
    return (
        <div id={'scans-left'} className={'form-text tg-text text-center'}>
            {scansLeftText}
            {isAdmin && (
                <TgLink className={'ms-1'} onClick={resetDailyScans}>
                    <MDBIcon icon={'fa fa-refresh'}/>
                </TgLink>
            )}
        </div>
    )
}


export function ShoppersSelect(
    {
        isAdmin,
        shoppers, createShopper,
        activeShopperIDX, setActiveShopperIDX,
        createShopperButton,
    }
) {
    return (
        <MDBSelect
            className={'m-2 tg-bg tg-text tg-select'}
            inputClassName={'tg-bg tg-text'}
            data={shoppers.map((shopper, idx) => {
                return {
                    text: shopper.name,
                    icon: `${ApiUrl}/${shopper.photo_path}`,
                    value: idx,
                    defaultSelected: idx === activeShopperIDX
                }
            })}
            onValueChange={(data) => {
                setActiveShopperIDX(data.value)
            }}
        >
            {isAdmin && (
                <TgButton onClick={createShopper} className={'w-100'}>
                    <MDBIcon fas icon="plus" className={'me-2'}/>
                    {createShopperButton}
                </TgButton>
            )}
        </MDBSelect>
    )
}


function Scanner( { onCheckScanned } ) {
    const { ref } = useZxing({
        onResult(result) {
            onCheckScanned(result.getText());
        },
    });


    return <video controls={false} playsInline autoPlay className={'w-100'} ref={ref} />
}


export function QrScannerModal(
    {
        show, setShow, onResult,
        enterCodeLabel, enterCodeButtonText,
        cancelButtonText, launchButtonText,
    } ) {
    const [isLaunchedScanner, setIsLaunchedScanner] = useState(false)

    const codeInputRef = useRef(null)

    const onCheckScanned = (receiptData, isCode=false) => {
        onResult(receiptData, isCode)
        setShow(false)
    }

    return (
        <MDBModal
            show={show}
            tabIndex='-1'
            setShow={setShow}
            className={'tg-modal'}
            onHide={() => setIsLaunchedScanner(false)}
        >
              <MDBModalDialog position='top-right' side>
                  <MDBModalContent>
                      <MDBModalHeader>
                          <MDBModalTitle>Scan QR-code</MDBModalTitle>
                          <MDBBtn
                              color='none'
                              className='btn-close btn-close-white'
                              onClick={() => setShow(!show)}
                          ></MDBBtn>
                      </MDBModalHeader>
                      <MDBModalBody>
                          {show && (
                              <>
                                  {isLaunchedScanner ? (
                                      <Scanner onCheckScanned={onCheckScanned}/>
                                  ): (
                                      <div className={'d-flex align-items-center w-100 justify-content-center'}>
                                          <div className={'d-inline-block tg-text'}>
                                              <MDBInput
                                                  wrapperClass={'tg-text'}
                                                  labelClass={'tg-text'}
                                                  className={'tg-text'}
                                                  inputRef={codeInputRef}
                                                  label={enterCodeLabel}
                                              />
                                          </div>
                                          <div className={'d-inline-block ms-2'}>
                                              <TgButton onClick={e => {
                                                  e.preventDefault()
                                                  setTimeout(() => {
                                                      onCheckScanned(codeInputRef.current.value, true)
                                                  }, 250)
                                              }}>
                                                  <MDBIcon fas icon="angle-right" size={'xl'}/>
                                              </TgButton>
                                          </div>
                                      </div>
                                  )}
                              </>
                          )}
                      </MDBModalBody>
                      <MDBModalFooter>
                          {!isLaunchedScanner ? (
                              <TgButton onClick={() => {
                                  setTimeout(() => {
                                      setIsLaunchedScanner(true)
                                  }, 250)
                              }}>
                                  {launchButtonText}
                              </TgButton>
                          ) : (
                              <TgButton onClick={(e) => {
                                  e.preventDefault()
                                  setTimeout(() => {
                                      setIsLaunchedScanner(false)
                                  }, 250)
                              }}>
                                  {enterCodeButtonText}
                              </TgButton>
                          )}
                          <TgButton onClick={() => {
                              setTimeout(() => {
                                  setShow(false)
                              }, 250)
                          }}>
                              {cancelButtonText}
                          </TgButton>
                      </MDBModalFooter>
                  </MDBModalContent>
              </MDBModalDialog>
        </MDBModal>
    )
}
