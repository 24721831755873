import {MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import useActiveSet from "../../hooks/useActiveShopperTemplatesSet";
import PartsFilters from "./PartsFilters";
import {useState} from "react";
import {ShopperTemplatesSetPartsType} from "../../../../../../../api/ShopperTemplates/ShopperTemplatesSets/types";
import PartCard from "./PartCard";
import {useOutletContext} from "react-router-dom";
import {ShopperTemplatesContext} from "../../../../types";
import AddPartCard from "./AddPartCard";
import api from "../../../../../../../api";


interface PartsProps {
    deletePartsTypeButton: string
}


export default function Parts(props: PartsProps) {
    const {updateTemplatesSet} = useOutletContext<ShopperTemplatesContext>()
    const activeSet = useActiveSet()
    const [activePart, setActivePart] = useState<keyof ShopperTemplatesSetPartsType>('bodies')

    if (!activeSet?.parts) {
        return null
    }

    return (
        <MDBContainer fluid>
            <PartsFilters
                activePart={activePart}
                setActivePart={setActivePart}
                deletePartsTypeButton={props.deletePartsTypeButton}
                deletePartsType={async (partType) => {
                    if (!activeSet?.parts) {
                        return
                    }

                    try {
                        const response = await api.shopperTemplates.sets.deleteParts(activeSet.id, partType)
                        if (response.data.part_type === 'all') {
                            updateTemplatesSet(activeSet.id, {
                                parts: {
                                    bodies: [],
                                    horns: [],
                                    hands: [],
                                    mouths: [],
                                    eyes: [],
                                    stars: [],
                                }
                            })
                        } else {
                            updateTemplatesSet(activeSet.id, {
                                parts: {...activeSet.parts, [response.data.part_type]: []}
                            })
                        }
                    } catch (err: any) {
                        console.log(err)
                        alert(err?.response?.data?.detail || 'Unknown error')
                    }
                }}
            />
            <MDBRow className={'mt-2 align-items-stretch'}>
                <MDBCol key={'add-part'} size={12} sm={6} lg={4} xl={3}>
                    <AddPartCard
                        set={activeSet}
                        updateSet={updateTemplatesSet}
                        partType={activePart}
                    />
                </MDBCol>

                {activeSet.parts[activePart].map((part, idx) => {
                    return (
                        <MDBCol key={part} size={12} sm={6} lg={4} xl={3} className={'mt-3'}>
                            <PartCard idx={idx} part={part} partType={activePart}/>
                        </MDBCol>
                    )
                })}
            </MDBRow>
        </MDBContainer>
    )
}
