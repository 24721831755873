import {
    MDBBtn, MDBCard,
    MDBCardBody,
    MDBCardFooter, MDBCardHeader, MDBCheckbox, MDBInput, MDBSelect,
    MDBSpinner
} from "mdb-react-ui-kit";
import api from "../../../../../../api";
import {useState} from "react";
import {useOutletContext} from "react-router-dom";
import {ShopperTemplatesContext} from "../../../types";
import {ShopperTemplatesSetTypeType} from "../../../../../../api/ShopperTemplates/ShopperTemplatesSets/types";


interface CreateShopperTemplatesSetCardProps {
    createSetHeader: string
    createSetButton: string
    isActiveLabel: string
    isPrivateLabel: string
    nameLabel: string
    huePercentLabel: string
    typeLabel: string
    default_generateType: string
    generateType: string
    imagesType: string
}


export default function CreateShopperTemplatesSetCard(props: CreateShopperTemplatesSetCardProps) {
    const {setShopperTemplatesSets} = useOutletContext<ShopperTemplatesContext>()
    const [isLoading, setIsLoading] = useState(false)

    const [isActive, setIsActive] = useState(true)
    const [isPrivate, setIsPrivate] = useState(false)
    const [name, setName] = useState('')
    const [type, setType] = useState<ShopperTemplatesSetTypeType>('default_generate')
    const [huePercent, setHuePercent] = useState(50)

    return (
        <MDBCard className={'h-100'}>
            <MDBCardHeader>
                {props.createSetHeader}
            </MDBCardHeader>
            <MDBCardBody className={'px-3'}>
                <form>
                    <MDBCheckbox
                        label={props.isActiveLabel}
                        checked={isActive}
                        onChange={event => setIsActive(event.target.checked)}
                    />
                    <MDBCheckbox
                        label={props.isPrivateLabel}
                        checked={isPrivate}
                        onChange={event => setIsPrivate(event.target.checked)}
                        wrapperClass={'mt-2'}
                    />
                    <MDBInput
                        wrapperClass={'mt-2'}
                        label={props.nameLabel}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                    <MDBInput
                        wrapperClass={'mt-2'}
                        label={props.huePercentLabel}
                        value={huePercent}
                        onChange={(event) => setHuePercent(parseInt(event.target.value))}
                    />
                    <MDBSelect
                        className={'mt-2'}
                        label={props.typeLabel}
                        data={[
                            {
                                text: props.default_generateType,
                                value: 'default_generate',
                                defaultSelected: type === 'default_generate',
                            },
                            {
                                text: props.generateType,
                                value: 'generate',
                                defaultSelected: type === 'generate',
                            },
                            {
                                text: props.imagesType,
                                value: 'images',
                                defaultSelected: type === 'images',
                            }
                        ]}
                        onValueChange={(data) => {
                            setType((data as {value: ShopperTemplatesSetTypeType}).value)
                        }}
                    />
                </form>
            </MDBCardBody>
            <MDBCardFooter className={'px-3'}>
                <MDBBtn disabled={isLoading || !name || !type} onClick={() => {
                    setIsLoading(true)
                    api.shopperTemplates.sets.createSet({
                        is_active: isActive,
                        is_private: isPrivate,
                        name: name,
                        type: type,
                        hue_percent: huePercent,
                    }).then(response => {
                        setIsLoading(false)
                        setShopperTemplatesSets(prevState => (
                            [response.data, ...prevState]
                        ))
                    }).catch(err => {
                        setIsLoading(false)
                        console.log(err)
                        alert(err?.response?.data?.detail || 'Unknown error')
                    })
                }}>
                    {isLoading && (
                        <MDBSpinner size={'sm'} role={'status'} tag={'span'} className={'me-2'} />
                    )}
                    {props.createSetButton}
                </MDBBtn>
            </MDBCardFooter>
        </MDBCard>
    )
}
