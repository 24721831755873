import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter, MDBCardHeader,
    MDBCardImage,
    MDBCheckbox, MDBIcon, MDBInput,
    MDBLazyLoading, MDBSpinner
} from "mdb-react-ui-kit";
import api from "../../../../../../../api";
import LoadingImage from "../../../../../../../Loading.gif";
import useActiveSet from "../../hooks/useActiveShopperTemplatesSet";
import {
    SetTemplates,
} from "../../../../../../../api/ShopperTemplates/ShopperTemplatesSets/types";
import {useOutletContext} from "react-router-dom";
import {ShopperTemplatesContext} from "../../../../types";
import {ShopperTemplate, UpdateShopperTemplateData} from "../../../../../../../api/ShopperTemplates/types";
import {useEffect, useState} from "react";


export type TemplateCardTexts = {
    nameLabel: string
    isActiveLabel: string
    deleteButton: string
}


interface TemplateCardProps extends TemplateCardTexts{
    template: ShopperTemplate
    rarity: keyof SetTemplates
    idx: number
}


export default function TemplateCard({template, rarity, idx, ...props}: TemplateCardProps) {
    const {updateTemplatesSet} = useOutletContext<ShopperTemplatesContext>()
    const activeSet = useActiveSet()

    const [name, setName] = useState('')
    const [isActive, setIsActive] = useState(false)
    const [isNameLoading, setIsNameLoading] = useState(false)

    useEffect(() => {
        setName(template.name)
        setIsActive(template.is_active)
        setIsNameLoading(false)
    }, [template.name, template.is_active])

    const src = api.getFileUrl(template.photo_path)

    const onClick = () => {
        if (!activeSet || !activeSet.templates) return

        const templates = activeSet.templates as SetTemplates

        api.shopperTemplates.delete(template.id).then(response => {
            const data = response.data

            updateTemplatesSet(activeSet.id, {
                ...activeSet,
                templates: {
                    ...templates,
                    [rarity]: templates[rarity].filter(el => el.id !== data.id)
                }
            })
        })
    }

    const updateTemplate = (data: UpdateShopperTemplateData) => {
        if (!activeSet) return

        const templates = activeSet.templates

        if (!templates) return

        if (data.name) {
            if (isNameLoading) {
                return
            }
            setIsNameLoading(true)
        }

        api.shopperTemplates.update(template.id, data).then(response => {
            if (data.name) {
                setIsNameLoading(false)
            }
            updateTemplatesSet(
                activeSet.id, {
                    ...activeSet,
                    templates: {
                        ...templates,
                        [rarity]: templates[rarity].map(template => {
                            if (template.id === response.data.id) {
                                return response.data
                            } else {
                                return template
                            }
                        })
                    }
                }
            )
        }).catch(err => {
            if (data.name) {
                setIsNameLoading(false)
            }
            console.log(err)
            alert(err?.response?.data?.detail || 'Unknown error')
        })
    }

    return (
        <MDBCard className={'h-100'}>
            {idx > 4 ? (
                <MDBLazyLoading
                  lazySrc={src}
                  lazyPlaceholder={LoadingImage}
                  alt={template.name}
                  className={'img-fluid'}
                />
            ) : (
                <MDBCardImage
                    src={src}
                    alt={template.name}
                    className={'img-fluid'}
                />
            )}
            <MDBCardHeader>
                {template.name}
            </MDBCardHeader>
            <MDBCardBody>
                <MDBCheckbox
                    label={props.isActiveLabel}
                    checked={isActive}
                    onChange={(event) => {
                        setIsActive(event.target.checked)
                        updateTemplate({is_active: event.target.checked})
                    }}
                />
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}>
                    <MDBInput
                        name={'name'}
                        label={props.nameLabel}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        wrapperClass={'mt-2'}
                    />
                    {isNameLoading ? (
                        <MDBSpinner role={'status'} size={'sm'}/>
                    ) : (
                        <a href={'#'} className={'ms-2 mt-2'} onClick={(event) => {
                            event.preventDefault()
                            updateTemplate({name})
                        }}>
                            <MDBIcon icon={'fa fa-check'} size={'xl'}/>
                        </a>
                    )}
                </div>
            </MDBCardBody>
            <MDBCardFooter>
                <MDBBtn className={'btn-danger'} onClick={onClick}>
                    {props.deleteButton}
                </MDBBtn>
            </MDBCardFooter>
        </MDBCard>
    )
}
