import {useEffect, useState} from "react";
import {generateShopperImage} from "../../../api/shopper";
import {useParams} from "react-router-dom";
import {useMDBStorage} from "mdb-react-storage-managament";
import LoginRegister from "../../loginRegister/page";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardImage, MDBCol,
    MDBContainer,
    MDBIcon,
    MDBInput, MDBRow,
    MDBSelect, MDBSpinner
} from "mdb-react-ui-kit";


export default function GenerateImagePage() {
    const params = useParams()

    const [tokenInStorage, {setItem, removeItem}] = useMDBStorage('token', null)
    const [token, setTokenState] = useState(tokenInStorage)

    const [image, setImage] = useState('')
    const [loading, setLoading] = useState(false)
    const [loadingTimeout, setLoadingTimeout] = useState(null)
    const [error, setError] = useState(null)

    const [rarity, setRarity] = useState('common')
    const [huePercent, setHuePercent] = useState(50)

    const setToken = (token) => {
        if (token === undefined){
            token = null
        }
        setItem(token)
        setTokenState(token)
    }

    const removeToken = () => {
        removeItem()
        setTokenState(null)
    }

    const onLogin = (result) => {
        if (result) {
            setToken(result.token)
        }
    }

    const generateAndSetShopperImage = (rarity, huePercent, token, lang) => {
        if (token) {
            setLoading(true)
            clearTimeout(loadingTimeout)
            setLoadingTimeout(setTimeout(() => {
                setLoading(false)
            }, 10000))
            generateShopperImage(rarity, huePercent, token, lang).then(
                response => {
                    if (response.status === 200) {
                        setImage(response.data.image_url)
                        setError(null)
                    } else {
                        setError(response.data.detail)
                        setLoading(false)
                    }
                }
            ).catch((err) => {
                console.log(err)
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        generateAndSetShopperImage(rarity, huePercent, token, params.lang)
    }, [token])

    return (
        <MDBContainer className={'d-flex align-items-center justify-content-center'}>
            {token? (
                <MDBCard style={{width: '450px'}}>
                    <MDBCardImage src={image} onLoad={() => setLoading(false)}/>
                    <MDBCardBody>
                        {error && (
                            <h3 className={'text-error text-center'}>
                                {error}
                            </h3>
                        )}
                        <MDBSelect
                            id={'rarity'}
                            name={'rarity'}
                            data={[
                                {text: 'common', value: 'common'},
                                {text: 'uncommon', value: 'uncommon'},
                                {text: 'rare', value: 'rare'},
                                {text: 'epic', value: 'epic'},
                                {text: 'legendary', value: 'legendary'},
                            ].map((el) => {
                                return {...el, defaultSelected: el.value === rarity}
                            })}
                            onValueChange={(data) => setRarity(data.value)}
                        />
                        <MDBInput
                            id={'huePercent'}
                            name={'huePercent'}
                            label={'Colored percent'}
                            className={'mt-3'}
                            value={huePercent}
                            min={0}
                            max={100}
                            type={'number'}
                            onChange={(e => {
                                setHuePercent(e.target.value)
                            })}
                        />
                        <MDBCardFooter className={'px-0 pb-1'}>
                            <MDBRow className={'w-100 m-0'}>
                                <MDBCol size={6} className={'ps-0 pe-1'}>
                                    <MDBBtn disabled={loading} className={'w-100'} onClick={() => {
                                        generateAndSetShopperImage(rarity, huePercent, token, params.lang)
                                    }}>
                                        {loading && (
                                            <MDBSpinner color='light' size={'sm'}>
                                                <span className='visually-hidden'>Loading...</span>
                                            </MDBSpinner>
                                        )}
                                        <span className={'ms-2'}>Generate</span>
                                    </MDBBtn>
                                </MDBCol>
                                <MDBCol size={6} className={'ps-1 pe-0'}>
                                    <MDBBtn className={'w-100'} onClick={removeToken} href={'#'}>
                                        <span className={'me-2'}>Logout</span>
                                        <MDBIcon icon={'fa fa-sign-out'} size={'xl'}/>
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                        </MDBCardFooter>
                    </MDBCardBody>
                </MDBCard>
            ) : (
                <div style={{maxWidth: '450px'}}>
                    <LoginRegister
                        lang={params.lang}
                        onLogin={onLogin}
                    />
                </div>
            )}
        </MDBContainer>
    )

}
