import {localiseList} from "./api/localise";


export type ToLocaliseValue = {
    variable: string
    lang: string
    kwargs?: object
}

export type toLocalise<T extends {[p: string]: string} = {[p: string]: string}> = {
    [Key in keyof T]: ToLocaliseValue
}

export type LocalisedTexts<K extends toLocalise> = {
    [Key in keyof K]: string
}


export function localiseListAndSave<T extends toLocalise>(
    setLocalisedTexts: ((newTexts: LocalisedTexts<T>) => any),
    toLocalise: T
) {
    const fieldNames = Object.keys(toLocalise)
    const listToLocalise = Object.values(toLocalise)

    localiseList(listToLocalise).then(
        (texts: string[]) => {
            const newLocalisedTexts: {[p: string]: string} = {}
            texts.forEach((text, i) => {
                newLocalisedTexts[fieldNames[i]] = text
            })
            setLocalisedTexts(newLocalisedTexts as LocalisedTexts<T>)
        }
    )
}


export function generateRandomString(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}


export function getMobileOperatingSystem(): 'android' | 'ios' | 'other' {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
        return "android";
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "ios";
    }

    return "other"
}
