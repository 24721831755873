import AdminNavbar from "./navbar";
import {Outlet, useOutletContext} from "react-router-dom";
import {useEffect, useLayoutEffect, useRef, useState} from "react";


export default function Admin() {
    const context = useOutletContext()

    const navbarRef = useRef(null)
    const [navbarHeight, setNavbarHeight] = useState(0)

    const {user, removeToken} = context

    const [showNotAdminError, setShowNotAdminError] = useState(false)
    const [timer, _setTimer] = useState(null)
    const timerRef = useRef(timer)

    const [secondsLeft, _setSecondsLeft] = useState(5)
    const secondsRef = useRef(secondsLeft)

    const setTimer = (value) => {
        timerRef.current = value
        _setTimer(value)
    }

    const setSecondsLeft = (left) => {
        secondsRef.current = left
        _setSecondsLeft(left)
    }


    useEffect(() => {
        clearTimeout(timer)
        if (!user.is_admin) {
            setShowNotAdminError(true)
            setSecondsLeft(5)
            setTimer(setInterval(() => {
                if (secondsRef.current === 1 ) {
                    clearTimeout(timerRef.current)
                    removeToken()
                } else {
                    setSecondsLeft(secondsRef.current - 1)
                }
            }, 1000))
        } else {
            setShowNotAdminError(false)
        }

        return () => clearTimeout(timer)
    }, [user.is_admin])

    useLayoutEffect(() => {
        setNavbarHeight(navbarRef.current.height)
    }, [])

    if (showNotAdminError) {
        return (
            <div
                className={'w-100 d-flex align-items-center justify-content-center'}
                style={{
                    minHeight: '100vh'
                }}
            >
                <div>
                    <h1>
                        You are not admin. You will be redirected to login page in {secondsLeft} seconds
                    </h1>
                </div>
            </div>
        )
    }

    return (
        <>
            <AdminNavbar user={user} logout={removeToken} navbarRef={navbarRef} />
            <div style={{marginTop: `${navbarHeight}px`}}>
                <div className={'pt-5'}>
                    <Outlet context={context}/>
                </div>
            </div>
        </>
    )
}
