import {useSearchParams} from "react-router-dom";


export default function TestDeepLinkPage() {
    const [searchParams] = useSearchParams()
    const link = searchParams.get("link")
    window.open(link, 'blank_')
    return <div style={{width: '100%', height: '100vh'}} className={'d-flex justify-content-center align-items-center'}>
        {link}
    </div>
}
