import {Dispatch, SetStateAction, useEffect, useState} from "react";
import api from "../../../../api";
import {Certificate} from "../../../../api/Certificates/types";


export default function useFetchCertificates(): [
    certificates: Certificate[],
    setCertificates: Dispatch<SetStateAction<Certificate[]>>,
    reload: () => void
] {
    const [certificates, setCertificates] = useState<Certificate[]>([])
    const [reload, setReload] = useState(0)

    useEffect(() => {
        api.certificates.getList().then(
            response => {
                setCertificates(response.data)
            }
        )
    }, [reload])

    return [certificates, setCertificates, () => setReload(prevState => prevState + 1)]
}
