import {
    MDBAutocomplete,
    MDBBtn, MDBBtnGroup,
    MDBCard,
    MDBCardBody, MDBCardImage,
    MDBCardTitle,
    MDBCol, MDBContainer, MDBFile, MDBIcon, MDBInput,
    MDBLazyLoading,
    MDBProgress,
    MDBProgressBar, MDBRange,
    MDBRow, MDBSelect, MDBSpinner,
} from "mdb-react-ui-kit";
import {ApiUrl} from "../../../config";

import LoadingImage from '../../../loading.png'
import {useEffect, useRef, useState} from "react";
import {createShopper, generateAttributes, generateShopperImage, generateShopperName} from "../../../api/shopper";
import {useWindowWidth} from "@react-hook/window-size";
import useMergeState from "../../../mergedState";
import {getUsersList} from "../../../api/user";


export function Shoppers( { shoppers, token, lang, updateShopperOwner } ) {
    if (!shoppers) {
        return null
    }

    const getSetOwner = (shopperID) => (ownerID) => {
        updateShopperOwner(shopperID, ownerID)
    }

    return (
        <MDBRow className={'align-items-stretch'}>
            {shoppers.length < 1 && (
                <div className={'d-flex align-items-center justify-content-center'} style={{height: '100vh'}}>
                    <div><h3>No shoppers found</h3></div>
                </div>
            )}
            {shoppers.map((shopper, idx) => {
                return (
                    <MDBCol key={shopper.id} size={12} md={6} lg={4} xxl={3} className={'my-3'}>
                        <Shopper
                            idx={idx}
                            shopper={shopper}
                            token={token} lang={lang}
                            setOwner={getSetOwner(shopper.id)}
                        />
                    </MDBCol>
                )
            })}
        </MDBRow>
    )
}


function Shopper( { shopper, setOwner, token, lang, idx } ) {
    const src = `${ApiUrl}/${shopper.photo_path}`

    return (
        <MDBCard className={'h-100'}>
            {idx > 2 ? (
                <MDBLazyLoading
                  lazySrc={src}
                  lazyPlaceholder={LoadingImage}
                  alt={shopper.name}
                  className={'img-fluid'}
                />
            ) : (
                <MDBCardImage
                    src={src}
                    alt={shopper.name}
                    className={'img-fluid'}
                />
            )}
            <MDBCardBody className={'p-3 d-flex align-items-end'}>
                <div className={'w-100'}>
                    <MDBCardTitle>
                        {shopper.name}
                    </MDBCardTitle>
                    <MDBRow className={'w-100 m-0'}>
                        <MDBCol size={shopper.owner ? 10 : 12} className={'px-0'}>
                            <SelectUser
                                token={token} lang={lang}
                                setUser={(user) => setOwner(user.id)}
                                currentOwnerName={shopper?.owner?.username || ''}
                            />
                        </MDBCol>
                        {shopper.owner && (
                            <MDBCol size={2} className={'d-flex align-items-center'}>
                                <a href={'#'} onClick={e => {
                                    e.preventDefault()
                                    setOwner(null)
                                }}>
                                    <MDBIcon fas icon="trash-alt" size={'xl'}/>
                                </a>
                            </MDBCol>
                        )}
                    </MDBRow>

                    <Attributes shopper={shopper}/>
                </div>
            </MDBCardBody>
        </MDBCard>
    )
}

function Attributes( { shopper } ) {
    return ['level', 'efficiency', 'endurance', 'lucky', 'intuition'].map(
        paramName => {
            return (
                <Attribute
                    key={paramName}
                    paramName={paramName}
                    shopper={shopper}
                />
            )
        }
    )
}


function Attribute( { paramName, shopper } ) {
    const attributesColors = {
        "level": "bg-secondary",
        "efficiency": "bg-primary",
        "endurance": "bg-success",
        "intuition": "bg-danger",
        "lucky": "bg-warning",
    }

    return (
        <MDBRow className={'m-0 mt-2 w-100' + (paramName === 'level' ? ' mb-4' : '')}>
            <MDBCol size={4} className={'px-0 text-start'}>
                {paramName.charAt(0).toUpperCase() + paramName.slice(1)}
            </MDBCol>
            <MDBCol size={6} className={'m-auto ps-0 pe-0'}>
                <MDBProgress className={'rounded'} height={15}>
                    <MDBProgressBar
                        className={'rounded-start ' + attributesColors[paramName]}
                        width={paramName === 'level' ? shopper[paramName] * 5 : shopper[paramName]}
                    />
                </MDBProgress>
            </MDBCol>
            <MDBCol size={2} className={'ps-1 pe-0 text-end pe-0'}>
                {shopper[paramName]}
            </MDBCol>
        </MDBRow>
    )
}


export function CreateShopper( {token, lang, onCreated, onError} ) {
    const windowWidth = useWindowWidth()

    const fileInputRef = useRef()

    const [image, setImage] = useState('')
    const [isImageSetManually, setIsImageSetManually] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingTimeout, setLoadingTimeout] = useState(null)
    const [error, setError] = useState(null)

    const [ownerID, setOwnerID] = useState(null)
    const [rarity, setRarity] = useState('common')
    const [huePercent, setHuePercent] = useState(50)

    const [name, setName] = useState('')
    const [level, setLevel] = useState(0)

    const [attributes, setAttributes] = useMergeState({
        efficiency: 1,
        endurance: 1,
        intuition: 1,
        lucky: 1,
    })
    const [minAttribute, setMinAttribute] = useState(1)
    const [maxAttribute, setMaxAttribute] = useState(100)
    const [sumAttributes, setSumAttributes] = useState(500)

    const generateAndSetShopperImage = () => {
        if (token) {
            setLoading(true)
            clearTimeout(loadingTimeout)
            setLoadingTimeout(setTimeout(() => {
                setLoading(false)
            }, 10000))
            generateShopperImage(rarity, huePercent, token, lang).then(
                response => {
                    if (response.status === 200) {
                        setImage(response.data.image_url)
                        setIsImageSetManually(false)
                        if (fileInputRef.current) {
                            fileInputRef.current.value = ""
                        }
                        setError(null)
                    } else {
                        setError(response.data.detail)
                        setLoading(false)
                    }
                }
            ).catch((err) => {
                console.log(err)
                setLoading(false)
            })
        }
    }

    const generateAndSetName = () => {
        if (token) {
            generateShopperName(token, lang).then(
                data => {
                    if (data) {
                        setName(data.full_name)
                    }
                }
            )
        }
    }

    const generateAndSetAttributes = () => {
        generateAttributes(rarity, token, lang).then(
            data => {
                if (data) {
                    setAttributes(data.attributes)
                    setMinAttribute(data.min)
                    setMaxAttribute(data.max)
                    setSumAttributes(data.sum)
                }
            }
        )
    }

    useEffect(() => {
        generateAndSetName()
    }, [token])

    useEffect(() => {
        if (!isImageSetManually) {
            generateAndSetShopperImage()
        }
    }, [token, rarity, huePercent])

    useEffect(() => {
        generateAndSetAttributes()
    }, [token, rarity])

    useEffect(() => {
        if (level > 20) {
            setLevel(20)
        }
    }, [level])

    const onSubmitCreateShopper = (e) => {
        e.preventDefault()
        if (!fileInputRef.current) {
            return
        }
        const [photo] = fileInputRef.current.files
        createShopper(token, lang, ownerID, rarity, photo, image, name, level, attributes).then(
            response => {
                if (response.status === 200) {
                    generateAndSetName()
                    generateAndSetShopperImage()
                    onCreated(response)
                } else {
                    onError(response)
                }
            }
        )
    }

    const imageComponent = <MDBCardImage className={'w-100'} src={image} onLoad={() => setLoading(false)}/>
    const generateImageComponent = (
        <>
            {error && (
                <h3 className={'text-error text-center'}>
                    {error}
                </h3>
            )}
            <MDBFile
                accept={'.png'}
                className={'mb-4'}
                label={'Put image instead of generated'}
                id={'photo'} name={'photo'}
                inputRef={fileInputRef}
                onChange={(e) => {
                    const [file] = e.target.files
                    if (file) {
                        setIsImageSetManually(true)
                        setImage(URL.createObjectURL(file))
                    }
                }}
            />
            <MDBSelect
                id={'rarity'}
                name={'rarity'}
                data={[
                    {text: 'common', value: 'common'},
                    {text: 'uncommon', value: 'uncommon'},
                    {text: 'rare', value: 'rare'},
                    {text: 'epic', value: 'epic'},
                    {text: 'legendary', value: 'legendary'},
                ].map((el) => {
                    return {...el, defaultSelected: el.value === rarity}
                })}
                onValueChange={(data) => setRarity(data.value)}
            />
            <MDBInput
                id={'huePercent'}
                name={'huePercent'}
                label={'Colored percent'}
                className={'mt-3'}
                value={huePercent}
                min={0}
                max={100}
                type={'number'}
                onChange={(e => {
                    setHuePercent(e.target.value)
                })}
            />
            <div className={'p-3 w-100 text-center position-relative'}>
                {loading && (
                    <MDBSpinner color={'secondary'} size={'sm'}>
                        <span className='visually-hidden'>Loading...</span>
                    </MDBSpinner>
                )}
                <a href={'#'} className={'w-100 ms-2 text-secondary'} onClick={(e) => {
                        e.preventDefault()
                        generateAndSetShopperImage()
                    }}>
                    Generate new image
                </a>
            </div>
        </>
    )

    const createShopperForm = (
        <div className={'h-100 py-3'}>
            <h3 className={'mb-5'}>Create shopper</h3>
            <MDBRow className={'d-flex justify-content-space-between w-100'}>
                <MDBCol size={11}>
                    <MDBInput className={'w-100'} name={'shopperName'} label={'Shopper name'} value={name} onChange={(e) => {
                        setName(e.target.value)
                    }}/>
                </MDBCol>
                <MDBCol size={1}>
                    <a href={'#'} className={'text-primary'} onClick={(e) => {
                        e.preventDefault()
                        generateAndSetName()
                    }}>
                        <MDBIcon icon={'fa fa-refresh'} size={'xl'}/>
                    </a>
                </MDBCol>
            </MDBRow>
            <div className={'my-2'}>
                <MDBInput
                    value={level}
                    type={'number'}
                    label={'Level'}
                    min={0}
                    max={20}
                    step={1}
                    onChange={(event) => {
                        setLevel(prevState => parseInt(event.target.value) ?? prevState)
                    }}
                />
            </div>
            <div className={'my-3'}>
                <SelectUser
                    token={token} lang={lang}
                    userId={ownerID}
                    setUser={(user) => {
                        setOwnerID(user.id)
                    }}
                />
            </div>
            <div className={'mt-3'}>
                <CreateAttributes
                    attributes={attributes}
                    setAttributes={setAttributes}
                    generateAttributes={generateAndSetAttributes}
                    min={minAttribute}
                    max={maxAttribute}
                    sum={sumAttributes}
                />
            </div>
            <MDBBtn
                className={'w-100 p-3 fs-4'}
                onClick={onSubmitCreateShopper}
            >
                Create shopper
            </MDBBtn>
        </div>
    )

    return (
        <MDBCard className={'mb-5'} style={{maxWidth: '800px'}}>
            {windowWidth < 768 ? (
                <>
                    {imageComponent}
                    <MDBCardBody className={'pb-3'}>
                        {generateImageComponent}
                        {createShopperForm}
                    </MDBCardBody>
                </>
            ) : (
                <MDBRow className={'w-100'}>
                    <MDBCol md={6} lg={5}>
                        <MDBContainer className={'pb-3'}>
                            {imageComponent}
                            {generateImageComponent}
                        </MDBContainer>
                    </MDBCol>
                    <MDBCol md={6} lg={7} style={{
                        verticalAlign: 'bottom',
                    }}>
                        {createShopperForm}
                    </MDBCol>
                </MDBRow>
            )}
        </MDBCard>
    )
}


export function SelectUser( {token, lang, setUser, label='Owner', currentOwnerName=''} ) {
    const [value, setValue] = useState('')

    useEffect(() => {
        setValue(currentOwnerName)
    }, [currentOwnerName])

    return (
        <MDBAutocomplete
          required
          label={label}
          threshold={3}
          displayValue={user => user.username}
          dataFilter={async (search) => {
              if (search && search.length <= 2) {
                  return []
              }
              const response = await getUsersList(token, lang, search)
              if (response.status === 200) {
                  return response.data
              } else {
                  return []
              }
          }}
          onSelect={(user) => {
              setValue(user.username || '')
              setUser(user)
          }}
          value={value}
          itemContent={(user) => (
              <MDBRow>
                  <MDBCol size={2}>
                      <div className={'rounded-circle'}>
                          <img className={'img-fluid rounded-circle'} src={user.photo_url} alt={'avatar'}/>
                      </div>
                  </MDBCol>
                  <MDBCol size={10} className={'d-flex align-items-center'}>
                      <div className={'w-100 text-center'}>
                          {user.full_name && (
                              <span>{user.full_name}</span>
                          )}
                          {user.username && (
                              <span className={'ms-2'}>@{user.username}</span>
                          )}
                          {user.email && (
                              <span className={'ms-2'}><br/>{user.email}</span>
                          )}
                      </div>
                  </MDBCol>
              </MDBRow>
          )}
          onChange={(e) => {
              setValue(e.target.value)
          }}
        />
    )
}


function CreateAttributes( { attributes, setAttributes, generateAttributes, min, max, sum } ) {
    const setAttribute = (name) => (value) => {
        const newData = {}
        newData[name] = parseInt(value)
        setAttributes(newData)
    }

    const attributesSum = Object.values(attributes).reduce((a, b) => a + b, 0)

    return (
        <div>
            <div className={'d-flex justify-content-center align-items-center'}>
                <span className={'me-2 fs-4'}>Attributes</span>
                <a href={'#'} onClick={(e) => {
                    e.preventDefault()
                    generateAttributes()
                }}>
                    <MDBIcon icon={'fa fa-refresh'} size={'xl'}/>
                </a>
            </div>
            <div aria-describedby={'errorText'}>
                {['efficiency', 'endurance', 'lucky', 'intuition'].map(
                    attributeName => {
                        return (
                            <CreateAttribute
                                key={attributeName}
                                value={attributes[attributeName]}
                                setValue={setAttribute(attributeName)}
                                attributeName={attributeName}
                                min={min} max={max}
                            />
                        )
                    }
                )}
            </div>
            <div id={'errorText'} className={'text-center text-warning'}>
                {attributesSum > sum && (
                    `Attributes sum is greater than default. Default sum is ${sum}`
                )}
                {attributesSum < sum && (
                    `Attributes sum is less than default. Default sum is ${sum}`
                )}
            </div>
        </div>
    )
}


function CreateAttribute( { attributeName, value, setValue, min, max } ) {
    const minValue = 1
    const maxValue = 100

    if (value > maxValue) {
        setValue(maxValue)
    }
    if (value < minValue) {
        setValue(minValue)
    }

    let error = ''

    if (value < min) {
        error = `Value is len than default min value (${min})`
    } else if (value > max) {
        error = `Value is greater than default max value (${max})`
    }

    return (
        <>
            <MDBRow className={'w-100 m-0'}>
                <MDBCol size={12} className={'text-center'}>
                    <span aria-describedby={`${attributeName}ErrorText`}>{attributeName}: {value}</span>
                    {error && (
                        <div id={`${attributeName}ErrorText`} className={'text-warning text-center'}>
                            {error}
                        </div>
                    )}
                </MDBCol>
            </MDBRow>
            <MDBRow className={'d-flex justify-content-center align-items-center m-0 mb-3 w-100'}>
                <MDBCol size={1}>
                    <a
                        href={'#'}
                        onClick={(e) => {
                            e.preventDefault()
                            if (value-1 < minValue) {
                                return
                            }
                            setValue(value-1)
                        }}
                    >
                        <MDBIcon fas icon="minus" size={'xl'} />
                    </a>
                </MDBCol>
                <MDBCol size={10} className={'pe-0'}>

                    <MDBRange
                        className={'d-flex align-items-center'}
                        disableTooltip={true}
                        value={value}
                        min={minValue}
                        max={maxValue}
                        color={'danger'}
                        onChange={(event) => setValue(event.target.value)}
                    />
                </MDBCol>
                <MDBCol size={1}>
                    <a
                        href={'#'}
                        onClick={(e) => {
                            e.preventDefault()
                            if (value+1 > maxValue) {
                                return
                            }
                            setValue(value+1)
                        }}
                    >
                        <MDBIcon fas icon="plus" size={'xl'} />
                    </a>
                </MDBCol>
            </MDBRow>
        </>
    )
}


export function Filters( { withOwner, setWithOwner, search, setSearch } ) {
    const [searchTimer, setSearchTimer] = useState(null)
    const [searchValue, setSearchValue] = useState('')

    useEffect(() => {
        setSearchValue(search || '')
    }, [search])

    const checked = (isChecked) => {
        return isChecked ? 'checked': ''
    }

    const buttonClick = (withOwner) => (e) => {
        e.preventDefault()
        setWithOwner(withOwner)
    }

    const searchOnChange = (e) => {
        e.preventDefault()
        setSearchValue(e.target.value)
        clearTimeout(searchTimer)
        setSearchTimer(setTimeout(() => {
            setSearch(e.target.value)
            setSearchTimer(null)
        }, 1000))
    }

    return (
        <MDBContainer breakpoint={'xxl'} className={'d-flex align-items-start pb-4 w-100 justify-content-center'}>
            <MDBRow className={'w-100'}>
                <MDBCol size={12} lg={8}>
                    <MDBInput
                        wrapperClass={'w-100'} className={'w-100'}
                        value={searchValue} label={'Search...'}
                        onChange={searchOnChange}
                    />
                </MDBCol>
                <MDBCol size={12} lg={4} className={'mt-3 mt-lg-0'}>
                    <MDBBtnGroup>
                        <MDBBtn
                            name={'withOwner'}
                            onClick={buttonClick(true)}
                            className={checked(withOwner === true)}
                        >
                            Only <b>with</b> owner
                        </MDBBtn>
                        <MDBBtn
                            name={'withOwner'}
                            onClick={buttonClick(false)}
                            className={checked(withOwner === false)}
                        >Only <b>without</b> owner
                        </MDBBtn>
                        <MDBBtn
                            name={'withOwner'}
                            onClick={buttonClick(null)}
                            className={checked(withOwner === null)}
                        >
                            all
                        </MDBBtn>
                    </MDBBtnGroup>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}
