import {DELETE, GET, PATCH, POST} from "./base";
import {getHeaders} from "./helpers";


export async function getCompanies(search, token, lang) {
    const query = new URLSearchParams()
    if (search) {
        query.append('search', search);
    }
    return await GET(`/companies/?${query}`, {
        headers: getHeaders({token, lang})
    })
}


export async function revokeToken(companyID, token, lang) {
    return await POST(`/companies/${companyID}/revokeToken`, {}, {
        headers: getHeaders({token, lang}),
    }, true)
}


export async function updateCompany(companyID, data, token, lang) {
    return await PATCH(`companies/${companyID}/`, data, {
        headers: getHeaders({token, lang})
    }, true)
}


export async function createCompany(name, comment, token, lang) {
    return await POST('/companies/create', {
        name: name,
        comment: comment || '',
    }, {
        headers: getHeaders({token, lang})
    }, true)
}

export async function deleteCompany(companyID, token, lang) {
    return await DELETE(`companies/${companyID}/`, {
        headers: getHeaders({token, lang}),
    }, true)
}
