import React, { useState } from 'react';
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBCollapse,
} from 'mdb-react-ui-kit';
import {useLocation, useNavigate, useParams} from "react-router-dom";


function AdminNavLink( { active, to, children, ...props } ) {
    const params = useParams()
    const lang = params.lang

    const navigate = useNavigate()
    const goTo = (to) => (e) => {
        e.preventDefault()
        navigate(`/${lang}/admin${to}`)
    }

    return (
        <MDBNavbarLink active={active} href={'#'} onClick={goTo(to)} {...props}>
            {children}
        </MDBNavbarLink>
    )
}


export default function AdminNavbar( { user, logout, navbarRef } ) {
    const params = useParams()
    const [show, setShow] = useState(false);

    const location = useLocation()
    const navigate = useNavigate()

    const active = location.pathname.split('/').slice(-1)[0]

    return (
        <MDBNavbar ref={navbarRef} expand={'lg'} light bgColor={'light'} className={'w-100'}>
            <MDBContainer fluid className={'px-3'}>
                <MDBNavbarBrand href={'#'} onClick={(e) => {
                    e.preventDefault()
                    navigate(`/${params.lang}/admin/`)
                }}>
                    LipLep Admin
                </MDBNavbarBrand>

                <MDBNavbarToggler
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    aria-label='Toggle navigation'
                    onClick={() => setShow(!show)}
                >
                    <MDBIcon icon='bars' fas />
                </MDBNavbarToggler>

                <MDBCollapse navbar show={show}>
                    <MDBNavbarNav className={'mb-2 mb-lg-0'} style={{flex: 1}}>
                        <MDBNavbarItem>
                            <AdminNavLink active={active === 'admin' || active === ''} to={'/'}>
                                Home
                            </AdminNavLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <AdminNavLink active={active === 'shoppers'} to={'/shoppers'}>
                                Shoppers
                            </AdminNavLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <AdminNavLink active={active === 'generateImage'} to={'/generateImage'}>
                                Generate image
                            </AdminNavLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <AdminNavLink active={active === 'companies'} to={'/companies'}>
                                Companies
                            </AdminNavLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <AdminNavLink active={active === 'link'} to={'/link'}>
                                Link
                            </AdminNavLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <AdminNavLink
                                active={active === 'sets'}
                                to={'/shopperTemplates/sets'}
                            >
                                Shopper templates
                            </AdminNavLink>
                        </MDBNavbarItem>
                        <MDBNavbarItem>
                            <AdminNavLink
                                active={active === 'certificates'}
                                to={'/certificates'}
                            >
                                Certificates
                            </AdminNavLink>
                        </MDBNavbarItem>
                    </MDBNavbarNav>
                    <div>
                        <img
                            className={'rounded-circle img-fluid d-inline-block'}
                            src={user.photo_url}
                            alt={'avatar'}
                            style={{maxWidth: '40px'}}
                        />
                        <div className={'d-inline-block ms-1'}>
                            {user.username ? (
                                `@${user.username}`
                            ) : (
                                user.full_name
                            )}
                        </div>
                        <div className={'d-inline-block ms-1'}>
                            <a className={'text-dark'} href={'#'} onClick={(e) => {
                                e.preventDefault()
                                logout()
                            }}>
                                <MDBIcon icon={'fa fa-sign-out'} size={'xl'}/>
                            </a>
                        </div>
                    </div>
                </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
    );
}
