import {MDBContainer} from "mdb-react-ui-kit";
import {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import {localise} from "../../api/localise";


export default function PrivacyPolicyPage() {
    const {lang} = useOutletContext()
    const [text, setText] = useState('')

    useEffect(() => {
        localise('privacy policy text', lang).then(text => setText(text))
    }, [lang])

    return (
        <MDBContainer>
            <p>{text}</p>
        </MDBContainer>
    )
}
