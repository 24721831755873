import instance from "./instance"
import ShopperTemplatesApiModule from "./ShopperTemplates";
import {ApiUrl} from "../config";
import CertificatesApiModule from "./Certificates";


const api = {
    instance,
    getFileUrl: (path: string) => ApiUrl + (!path.startsWith('/') ? '/' : '') + path,
    shopperTemplates: ShopperTemplatesApiModule(instance),
    certificates: CertificatesApiModule(instance),
}


export default api;
