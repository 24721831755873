import {Navigate} from "react-router-dom";
import getUserLang from "./getUserLang";


export function NavigateToLang() {
    const lang = getUserLang()
    return <Navigate to={`/${lang}${window.location.pathname}${window.location.search}`}/>
}


export function NavigateToHome() {
    const lang = getUserLang()
    return <Navigate to={`/${lang}${window.location.search}`}/>
}
