import {MDBAlert, MDBCol, MDBContainer, MDBIcon, MDBRow} from "mdb-react-ui-kit";
import useFetchCertificates from "./hooks/useFetchCertificates";
import CertificateCard from "./components/CertificateCard";
import {CreateCertificateData, UpdateCertificateData} from "../../../api/Certificates/types";
import api from "../../../api";
import useLocalise from "../../../hooks/useLocalise";
import {useOutletContext} from "react-router-dom";
import {AppContext} from "../../../types";
import CreateCertificateCard from "./components/CreateCertificateCard";
import {useState} from "react";
import CertificateQrCodeModal from "./components/CertificateQrCodeModal";


export default function CertificatesPage() {
    const {lang} = useOutletContext<AppContext>()
    const [certificates, setCertificates] = useFetchCertificates()
    const [showUpdatedAlert, setShowUpdatedAlert] = useState(false)

    const [codeInModal, setCodeInModal] = useState<string | null>(null)

    const localisation = useLocalise({
        allowedUsagesLabel: {
            variable: 'certificate allowed usages label', lang
        },
        allowedUsagesPerUserLabel : {
            variable: 'certificate allowed usages per user label', lang
        },
        codeText: {
            variable: 'certificate code text', lang,
        },
        typeText: {
            variable: 'certificate type text', lang,
        },
        shopperTypeText: {
            variable: 'certificate shopper type text', lang,
        },
        shopperFromSetTypeText: {
            variable: 'certificate shopper from set type text', lang
        },
        promoCodeTypeText: {
            variable: 'certificate promo code type text', lang,
        },
        createHeader: {
            variable: 'certificate create header', lang,
        },
        createButton: {
            variable: 'certificate create button', lang,
        },
        typeLabel: {
            variable: 'certificate type label', lang,
        },
        codeLabel: {
            variable: 'certificate code label', lang,
        },
        shopperFromSetSelectLabel: {
            variable: 'certificate shopper from set select label', lang
        },
        shopperFromSetItemText: {
            variable: 'certificate shopper from set item text', lang,
        },
        privateText: {
            variable: 'private text', lang
        },
        confirmDeletionMessage: {
            variable: 'certificate confirm deletion message', lang,
        },
        deleteButton: {
            variable: 'certificate delete button', lang,
        },
        cancelDeletionButton: {
            variable: 'cancel certificate deletion button', lang,
        },
        levelLabel: {
            variable: 'certificate level label', lang,
        },
        rarityLabel: {
            variable: 'certificate rarity label', lang,
        },
        lipLabel: {
            variable: 'certificate lip label', lang,
        },
        lepLabel: {
            variable: 'certificate lep label', lang,
        },
        certificateQrCodeModalHeader: {
            variable: 'certificate qr code modal header', lang,
        },
        cancelButton: {
            variable: 'cancel button', lang,
        },
        downloadButton: {
            variable: 'download button', lang,
        }
    })

    const updateCertificate = (certificateID: number, data: UpdateCertificateData) => {
        api.certificates.update(certificateID, data).then(
            response => {
                setCertificates(prevState => prevState.map(certificate => {
                    if (certificate.id !== response.data.id) {
                        return certificate
                    } else {
                        return response.data
                    }
                }))
                setShowUpdatedAlert(true)
            }
        ).catch(err => {
            console.log(err)
            alert(err?.response?.data?.detail || 'Unknown error')
        })
    }

    const createCertificate = (data: CreateCertificateData) => {
        api.certificates.create(data).then(response => {
            setCertificates(prevState => [response.data, ...prevState])
        }).catch(err => {
            console.log(err)
            alert(err?.response?.data?.detail || 'Unknown error')
        })
    }

    const deleteCertificate = (certificateID: number) => {
        api.certificates.delete(certificateID).then(response => {
            setCertificates(prevState => prevState.filter(el => el.id !== response.data.id))
        }).catch(err => {
            console.log(err)
            alert(err?.response?.data?.detail || 'Unknown error')
        })
    }

    return (<>
        <MDBContainer fluid>
            <MDBRow className={'align-items-stretch'}>
                <MDBCol key={'create'} size={12} sm={6} lg={4} xl={4} xxl={3}>
                    <CreateCertificateCard
                        createCertificate={createCertificate}
                        createHeader={localisation.createHeader}
                        createButton={localisation.createButton}
                        typeLabel={localisation.typeLabel}
                        codeLabel={localisation.codeLabel}
                        allowedUsagesLabel={localisation.allowedUsagesLabel}
                        allowedUsagesPerUserLabel={localisation.allowedUsagesPerUserLabel}
                        shopperTypeText={localisation.shopperTypeText}
                        shopper_from_setTypeText={localisation.shopperFromSetTypeText}
                        promo_codeTypeText={localisation.promoCodeTypeText}
                        shopperTemplatesSetSelectLabel={localisation.shopperFromSetSelectLabel}
                        shopperTemplatesSetItemText={localisation.shopperFromSetItemText}
                        privateText={localisation.privateText}
                        rarityLabel={localisation.rarityLabel}
                        levelLabel={localisation.levelLabel}
                        lipLabel={localisation.lipLabel}
                        lepLabel={localisation.lepLabel}
                    />
                </MDBCol>
                {certificates.map(certificate => {
                    return (
                        <MDBCol key={certificate.id} size={12} sm={6} lg={4} xl={4} xxl={3}>
                            <CertificateCard
                                certificate={certificate}
                                updateCertificate={updateCertificate}
                                deleteCertificate={deleteCertificate}
                                codeText={localisation.codeText}
                                allowedUsagesLabel={localisation.allowedUsagesLabel}
                                allowedUsagesPerUserLabel={localisation.allowedUsagesPerUserLabel}
                                shopperTemplatesSetSelectLabel={localisation.shopperFromSetSelectLabel}
                                typeText={localisation.typeText}
                                shopperTypeText={localisation.shopperTypeText}
                                shopper_from_setTypeText={localisation.shopperFromSetTypeText}
                                promo_codeTypeText={localisation.promoCodeTypeText}
                                shopperTemplatesSetItemText={localisation.shopperFromSetItemText}
                                privateText={localisation.privateText}
                                confirmDeletionMessage={localisation.confirmDeletionMessage}
                                deleteButton={localisation.deleteButton}
                                cancelDeletionButton={localisation.cancelDeletionButton}
                                rarityLabel={localisation.rarityLabel}
                                levelLabel={localisation.levelLabel}
                                lipLabel={localisation.lipLabel}
                                lepLabel={localisation.lepLabel}
                                showQrModal={() => setCodeInModal(certificate.code)}
                            />
                        </MDBCol>
                    )
                })}
            </MDBRow>
            <MDBAlert
                autohide
                delay={5000}
                appendToBody
                position={'top-right'}
                show={showUpdatedAlert}
                onClosed={() => setShowUpdatedAlert(false)}
                color={'success'}
            >
                <MDBIcon icon={'fa fa-check'} className={'me-2'}/>Updated!
            </MDBAlert>

        </MDBContainer>
        <CertificateQrCodeModal
            code={codeInModal ?? ''}
            show={!!codeInModal}
            setShow={(show) => {
                if (show) {
                    console.warn('Unexpected attempt to show modal')
                } else {
                    setCodeInModal(null)
                }
            }}
            header={localisation.certificateQrCodeModalHeader}
            cancelButton={localisation.cancelButton}
            downloadButton={localisation.downloadButton}
        />
    </>)
}
