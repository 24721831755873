import {MDBCard, MDBCardBody, MDBCol, MDBLazyLoading, MDBRow} from "mdb-react-ui-kit";
import {ApiUrl} from "../../../config";

import LoadingImage from '../../../loading.png'


function Image( { imagePath } ) {
    return (
        <MDBCard>
            <MDBLazyLoading
              lazySrc={`${ApiUrl}/${imagePath}`}
              lazyPlaceholder={LoadingImage}
              alt={imagePath}
              className={'img-fluid'}
            />
            <MDBCardBody className={'p-1'}>
                {imagePath.replace('static/shopper_templates/default/common/', '').replace('.png', '').replaceAll('--', ' ')}
            </MDBCardBody>
        </MDBCard>
    )
}


export function Images( { images } ) {
    if (!images) {
        return null
    }

    return (
        <MDBRow>
            {images.map(image => {
                return (
                    <MDBCol size={12} md={6} lg={2} className={'my-3'}>
                        <Image imagePath={image}/>
                    </MDBCol>
                )
            })}
        </MDBRow>
    )
}
