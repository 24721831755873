import {
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalContent,
    MDBModalDialog,
    MDBModalFooter,
    MDBModalHeader, MDBSpinner
} from "mdb-react-ui-kit";
import {useEffect, useState} from "react";
import * as QRCode from "qrcode";
import {ThisUrl} from "../../../../config";


interface CertificateQrCodeModalProps {
    code: string
    show: boolean
    setShow: (show: boolean) => void
    header: string
    cancelButton: string
    downloadButton: string
}

const getUseLink = (code: string) => {
    return ThisUrl + '/useCertificate' + '?code=' + code
}


export default function CertificateQrCodeModal(props: CertificateQrCodeModalProps) {
    const [loading, setLoading] = useState(false)
    const [qrUrl, setQrUrl] = useState<string | null>(null)

    useEffect(() => {
        if (!props.show || loading) return

        setLoading(true)
        setQrUrl(null)

        QRCode.toDataURL(getUseLink(props.code), {
            errorCorrectionLevel: 'high',
            width: 512,
        }).then(url => {
            setLoading(false)
            setQrUrl(url)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }, [props.code, props.show, loading])

    return (
        <MDBModal tabIndex='-1' show={props.show} setShow={props.setShow} className={'tg-modal'}>
            <MDBModalDialog>
                <MDBModalContent>
                    <MDBModalHeader>{props.header.replace('{code}', props.code)}</MDBModalHeader>
                    <MDBModalBody>
                        {loading ? (
                            <MDBSpinner style={{width: '3rem', height: '3rem'}}/>
                        ) : qrUrl && <img src={qrUrl} alt={'qrcode'} className={'w-100'}/>}
                    </MDBModalBody>
                    <MDBModalFooter className={'d-flex justify-content-between'}>
                        <MDBBtn
                            className={'btn-danger'}
                            onClick={() => props.setShow(false)}
                        >
                            {props.cancelButton}
                        </MDBBtn>
                        {qrUrl && <MDBBtn
                            tag={'a'}
                            href={qrUrl}
                            download={'certificate-' + props.code + '.png'}
                        >
                            {props.downloadButton}
                        </MDBBtn>}
                    </MDBModalFooter>
                </MDBModalContent>
            </MDBModalDialog>
        </MDBModal>
    )
}
