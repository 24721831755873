import {useRef, useState} from "react";
import {MDBBtn, MDBCard, MDBCardBody, MDBCheckbox, MDBContainer, MDBIcon, MDBInputGroup} from "mdb-react-ui-kit";
import {getLink} from "../../../api/user";
import {useOutletContext} from "react-router-dom";


export default function LinkPage() {
    const {token} = useOutletContext()

    const inputRef = useRef(null)
    const checkBoxRef = useRef(null)

    const [link, setLink] = useState()

    const fetchAndSetLink = () => getLink(
        token, inputRef.current.value, checkBoxRef.current.checked ? ['me:read', 'me:write'] : ['me:read']
        ).then(data => {
        setLink(data.link)
    })

    return (
        <MDBContainer fluid className={'text-center'}>
            <div className={'mt-5'}>
                <MDBCard>
                    <MDBCardBody>
                        <MDBCheckbox
                            inputRef={checkBoxRef}
                            label={'with me:write'}
                        />
                        <MDBInputGroup>
                            {link && (
                                <a href={link} target={'_blank'}>
                                    <MDBIcon fas icon="external-link-alt"/>
                                </a>
                            )}
                            <input ref={inputRef} className={'form-control'} placeholder={'link base'}/>
                            <MDBBtn onClick={fetchAndSetLink}>
                                Get Link
                            </MDBBtn>
                        </MDBInputGroup>
                    </MDBCardBody>
                </MDBCard>
            </div>
        </MDBContainer>
    )
}
