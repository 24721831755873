import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCheckbox,
    MDBInput,
    MDBSpinner
} from "mdb-react-ui-kit";
import {useState} from "react";
import {
    SetTemplates,
} from "../../../../../../../api/ShopperTemplates/ShopperTemplatesSets/types";
import api from "../../../../../../../api";
import {MDBFileUpload} from "mdb-react-file-upload";
import useActiveSet from "../../hooks/useActiveShopperTemplatesSet";
import {useOutletContext} from "react-router-dom";
import {ShopperTemplatesContext} from "../../../../types";


export type CreateTemplatesCardTexts = {
    createTemplateHeader: string
    createTemplateButton: string
    isActiveLabel: string
    nameLabel: string
}


interface CreateTemplateCardProps extends CreateTemplatesCardTexts {
    rarity: keyof SetTemplates
}


export default function CreateTemplateCard(props: CreateTemplateCardProps) {
    const {updateTemplatesSet} = useOutletContext<ShopperTemplatesContext>()
    const activeSet = useActiveSet()

    const [file, setFile] = useState<File | null>(null)
    const [name, setName] = useState<string>('')
    const [isActive, setIsActive] = useState(true)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    if (!activeSet) return null

    const onClick = () => {
        const templates = activeSet.templates as SetTemplates
        if (!file || !name || !templates) return

        setIsLoading(true)
        api.shopperTemplates.create(
            activeSet.id,
            props.rarity,
            name,
            file,
            isActive,
        ).then(response => {
            setIsLoading(false)
            updateTemplatesSet(activeSet.id, {
                templates: {
                    ...templates,
                    [props.rarity]: [response.data, ...templates[props.rarity]]
                }
            })

            setFile(null)
            setName('')
        }).catch(err => {
            setIsLoading(false)
            console.log(err)
            alert(err?.response?.data?.detail || 'Unknown error')
        })
    }

    return (
        <MDBCard className={'h-100'} style={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
                {!isLoading && (
                    <MDBFileUpload
                        acceptedExtensions={'image/png'}
                        getInputFiles={([file]: File[]) => {
                            setFile(file)
                        }}
                        style={{height: file ? '100%' : undefined}}
                    />
                )}
            </div>
            <MDBCardHeader>
                {props.createTemplateHeader}
            </MDBCardHeader>
            <MDBCardBody
                style={{
                    flex: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <div/>
                <div>
                    <MDBCheckbox
                        label={props.isActiveLabel}
                        checked={isActive}
                        onChange={(event) => setIsActive(event.target.checked)}
                        name={'isActive'}
                    />
                    <MDBInput
                        required
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        name={'name'}
                        label={props.nameLabel}
                        className={'align-self-end'}
                        wrapperClass={'mt-2'}
                    />
                </div>
            </MDBCardBody>
            <MDBCardFooter>
                <MDBBtn
                    disabled={!name || !file}
                    onClick={onClick}
                    className={'d-flex align-items-center'}
                >
                    {isLoading && (
                        <MDBSpinner size={'sm'} role={'status'} tag={'span'} className={'me-2'} />
                    )}
                    {props.createTemplateButton}
                </MDBBtn>
            </MDBCardFooter>
        </MDBCard>
    )
}
