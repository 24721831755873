import {MDBBtn, MDBFile, MDBSpinner} from "mdb-react-ui-kit";
import {useRef, useState} from "react";
import api from "../../../../../../api";
import useActiveSet from "../hooks/useActiveShopperTemplatesSet";
import {useOutletContext} from "react-router-dom";
import {ShopperTemplatesContext} from "../../../types";


export default function ImportFromArchive({text}: {text: string}) {
    const activeSet = useActiveSet()
    const {updateTemplatesSet} = useOutletContext<ShopperTemplatesContext>()
    const fileInputRef = useRef<HTMLInputElement>()
    const [isFileSelected, setIsFileSelected] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const onImportClick = () => {
        const file = fileInputRef.current?.files?.item(0)
        if (!file || !activeSet) return

        setIsLoading(true)
        api.shopperTemplates.sets.importFromArchive(
            activeSet.id, file
        ).then(response => {
            if (fileInputRef.current) {
                // @ts-ignore
                fileInputRef.current.value = null
            }
            setIsLoading(false)
            updateTemplatesSet(activeSet.id, response.data)
        }).catch(err => {
            setIsLoading(false)
            console.log(err)
            alert(err?.response?.data?.detail || 'Unknown error')
        })
    }

    return (
        <div className={'d-flex w-100 justify-content-center mb-3'}>
            <MDBFile
                inputRef={fileInputRef}
                onChange={() => setIsFileSelected(true)}
                style={{maxWidth: 300}}
                accept={'.zip,.tar,.tar.gz'}
            />
            <MDBBtn
                disabled={!isFileSelected || isLoading}
                className={'btn-secondary ms-2'}
                onClick={onImportClick}
            >
                {isLoading && (
                    <MDBSpinner size={'sm'} role={'status'} tag={'span'} className={'me-2'} />
                )}
                {text}
            </MDBBtn>
        </div>
    )
}
