import {
    MDBCard,
    MDBCardBody, MDBCardFooter,
    MDBCardHeader,
    MDBIcon,
    MDBInput, MDBPopconfirm,
    MDBSelect,
    useClipboard
} from "mdb-react-ui-kit";
import {Certificate, CertificateRarity, UpdateCertificateData} from "../../../../api/Certificates/types";
import {useEffect, useRef, useState} from "react";
import ShopperTemplatesSetSelect from "./ShopperTemplatesSetSelect";


interface CertificateCardProps {
    certificate: Certificate
    updateCertificate: (certificateID: number, data: UpdateCertificateData) => void
    deleteCertificate: (certificateID: number) => void
    codeText: string
    allowedUsagesLabel: string
    allowedUsagesPerUserLabel: string
    shopperTemplatesSetSelectLabel: string
    shopperTemplatesSetItemText: string
    privateText: string
    typeText: string
    shopperTypeText: string
    shopper_from_setTypeText: string
    promo_codeTypeText: string
    deleteButton: string
    confirmDeletionMessage: string
    cancelDeletionButton: string
    rarityLabel: string
    levelLabel: string
    lipLabel: string
    lepLabel: string
    showQrModal: () => void
}


export default function CertificateCard({certificate, updateCertificate, ...props}: CertificateCardProps) {
    const [allowedUsagesCount, setAllowedUsagesCount] = useState(0)
    const [allowedUsagesPerUserCount, setAllowedUsagesPerUserCount] = useState(0)

    const [shopperTemplatesSetID, setShopperTemplatesSetID] = useState<number | null>(null)
    const [rarity, setRarity] = useState<CertificateRarity>('common')
    const [level, setLevel] = useState(0)

    const [lip, setLip] = useState(0)
    const [lep, setLep] = useState(0)

    const copyRef = useRef(null)

    useClipboard(certificate.code, copyRef)

    useEffect(() => {
        if (certificate.allowed_usages_count) {
            setAllowedUsagesCount(certificate.allowed_usages_count)
        }
    }, [certificate.allowed_usages_count])

    useEffect(() => {
        if (certificate.allowed_usages_per_user_count) {
            setAllowedUsagesPerUserCount(certificate.allowed_usages_per_user_count)
        }
    }, [certificate.allowed_usages_per_user_count])

    useEffect(() => {
        if (certificate.type === 'shopper_from_set') {
            setShopperTemplatesSetID(certificate.shopper_templates_set?.id)
        }
    }, [certificate.type === 'shopper_from_set' ? certificate.shopper_templates_set?.id : null])

    useEffect(() => {
        if (certificate.type === 'shopper_from_set') {
            setRarity(certificate.rarity)
        }
    }, [certificate.type === 'shopper_from_set' ? certificate.rarity : null])

    useEffect(() => {
        if (certificate.type === 'shopper_from_set') {
            setLevel(certificate.level ?? 0)
        }
    }, [certificate.type === 'shopper_from_set' ? certificate.level : null])

    useEffect(() => {
        setLip(certificate.lip ?? 0)
    }, [certificate.lip])

    useEffect(() => {
        setLep(certificate.lep ?? 0)
    }, [certificate.lep])

    return (
        <MDBCard className={'h-100'}>
            <MDBCardHeader>
                {'#' + certificate.id + ' ' + certificate.code}
            </MDBCardHeader>
            <MDBCardBody>

                <div className={'d-flex align-items-center p-1'}>
                    {props.codeText.replace(
                        '{code}', certificate.code
                    )}
                    <a className={'ms-2'} ref={copyRef} href={'#'} onClick={(event) => {
                        event.preventDefault()
                    }}>
                        <MDBIcon icon={'fa fa-copy'}/>
                    </a>
                    <a className={'ms-2'} href={'#'} onClick={(event) => {
                        event.preventDefault()
                        props.showQrModal()
                    }}>
                        <MDBIcon fas icon="qrcode" />
                    </a>
                </div>

                <UpdateInput
                    updateCertificate={updateCertificate}
                    certificateID={certificate.id}
                    data={{
                        allowed_usages_count: allowedUsagesCount
                    }}
                    className={'mt-2'}
                >
                    <MDBInput
                        type={'number'}
                        label={props.allowedUsagesLabel}
                        value={allowedUsagesCount}
                        onChange={(event) => setAllowedUsagesCount(parseInt(event.target.value))}
                    />
                </UpdateInput>

                <UpdateInput
                    updateCertificate={updateCertificate}
                    certificateID={certificate.id}
                    data={{
                        allowed_usages_per_user_count: allowedUsagesPerUserCount
                    }}
                    className={'mt-2'}
                >
                    <MDBInput
                        type={'number'}
                        label={props.allowedUsagesPerUserLabel}
                        value={allowedUsagesPerUserCount}
                        onChange={(event) => setAllowedUsagesPerUserCount(parseInt(event.target.value))}
                    />
                </UpdateInput>

                <div className={'p-1 mt-3'}>
                    {props.typeText.replace(
                        '{type}', props[`${certificate.type}TypeText`]
                    )}
                </div>

                {certificate.type === 'shopper_from_set' ? (<>
                    <UpdateInput
                        updateCertificate={updateCertificate}
                        certificateID={certificate.id}
                        data={shopperTemplatesSetID ? {
                            shopper_templates_set_id: shopperTemplatesSetID
                        } : {}}
                        className={'mt-1'}
                    >
                        <ShopperTemplatesSetSelect
                            label={props.shopperTemplatesSetSelectLabel}
                            chosenSetID={shopperTemplatesSetID}
                            setChosenSetID={setShopperTemplatesSetID}
                            itemText={props.shopperTemplatesSetItemText}
                            privateText={props.privateText}
                        />
                    </UpdateInput>

                    <UpdateInput
                        updateCertificate={updateCertificate}
                        certificateID={certificate.id}
                        data={{
                            rarity: rarity
                        }}
                    >
                        <MDBSelect
                            data={['common', 'uncommon', 'rare', 'epic', 'legendary'].map(el => ({
                                text: el, value: el, defaultSelected: rarity === el,
                            }))}
                            onValueChange={(data) => {
                                setRarity((data as {value: CertificateRarity}).value)
                            }}
                            label={props.rarityLabel}
                            className={'mt-2'}
                        />
                    </UpdateInput>

                    <UpdateInput
                        updateCertificate={updateCertificate}
                        certificateID={certificate.id}
                        data={{
                            level: level
                        }}
                        className={'mt-2'}
                    >
                        <MDBInput
                            type={'number'}
                            value={level}
                            onChange={(event) => setLevel(prevState => parseInt(event.target.value) ?? prevState)}
                            label={props.levelLabel}
                        />
                    </UpdateInput>

                </>) : (
                    <div/>
                )}

                <UpdateInput
                    updateCertificate={updateCertificate}
                    certificateID={certificate.id}
                    data={{lip}}
                    className={'mt-2'}
                >
                    <MDBInput
                        type={'number'}
                        value={lip}
                        onChange={(event) => setLip(prevState => parseInt(event.target.value) ?? prevState)}
                        label={props.lipLabel}
                    />
                </UpdateInput>

                <UpdateInput
                    updateCertificate={updateCertificate}
                    certificateID={certificate.id}
                    data={{lep}}
                    className={'mt-2'}
                >
                    <MDBInput
                        type={'number'}
                        value={lep}
                        onChange={(event) => setLep(prevState => parseInt(event.target.value) ?? prevState)}
                        label={props.lepLabel}
                    />
                </UpdateInput>

            </MDBCardBody>
            <MDBCardFooter>
                <MDBPopconfirm
                    cancelBtnText={props.cancelDeletionButton}
                    confirmBtnText={props.deleteButton}
                    btnChildren={props.deleteButton}
                    confirmBtnClasses={'btn-danger'}
                    btnClassName={'btn-danger'}
                    onConfirm={() => props.deleteCertificate(certificate.id)}
                >
                    <div className={'p-2'}>
                        {props.confirmDeletionMessage}
                    </div>
                </MDBPopconfirm>
            </MDBCardFooter>
        </MDBCard>
    )
}


interface UpdateInputProps {
    updateCertificate: (certificateID: number, data: UpdateCertificateData) => void
    certificateID: number
    data: UpdateCertificateData
    className?: string
    children: any
}


function UpdateInput({updateCertificate, certificateID, data, className, children}: UpdateInputProps) {
    return (
        <div className={'d-flex w-100 align-items-center' + ' ' + className || ''}>
            <div className={'flex-1'}>
                {children}
            </div>
            <a href={'#'} className={'ms-1'} onClick={() => updateCertificate(certificateID, data)}>
                <MDBIcon icon={'fa fa-check'} size={'xl'}/>
            </a>
        </div>
    )
}
