import {checkIsConfirmed} from "./api/user";


export function IsConfirmedChecker( token, callbackFunction ) {
    checkIsConfirmed(token).then(
        data => {
            if (data.is_confirmed) {
                callbackFunction(data.email);
            } else {
                setTimeout(() => IsConfirmedChecker(token, callbackFunction), 1000)
            }
        }
    )
}

