import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {ShopperTemplatesSetType} from "../../../../api/ShopperTemplates/ShopperTemplatesSets/types";
import api from "../../../../api";


export default function useLoadShopperTemplatesSets(): [
    ShopperTemplatesSetType[],
    Dispatch<SetStateAction<ShopperTemplatesSetType[]>>,
] {
    const [shopperTemplatesSets, setShopperTemplatesSets] = useState<ShopperTemplatesSetType[]>([])

    useEffect(() => {
        api.shopperTemplates.sets.getList().then(response => {
            setShopperTemplatesSets(response.data)
        })
    }, [])

    return [shopperTemplatesSets, setShopperTemplatesSets]

}
