import {useEffect, useState} from "react";
import {LocalisedTexts, localiseListAndSave, toLocalise} from "../helpers";


export default function useLocalise<T extends toLocalise>(
    toLocalise: T,
): LocalisedTexts<T> {
    const [localisedTexts, setLocalisedTexts] = useState<LocalisedTexts<T>>(
        Object.keys(toLocalise).reduce((obj, key) => ({...obj, [key]: ''}), {}) as LocalisedTexts<T>,
    )

    useEffect(() => {
        localiseListAndSave(setLocalisedTexts, toLocalise)
    }, [JSON.stringify(toLocalise)])

    return localisedTexts
}
