import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter, MDBCardHeader,
    MDBInput, MDBSpinner,
} from "mdb-react-ui-kit";
import {useState} from "react";
import api from "../../../../../../../api";
import {UpdateTemplatesSetType} from "../../../../types";
import {
    ShopperTemplatesSetPartsType,
    ShopperTemplatesSetType
} from "../../../../../../../api/ShopperTemplates/ShopperTemplatesSets/types";
import {MDBFileUpload} from "mdb-react-file-upload";


interface AddPartCardProps {
    set: ShopperTemplatesSetType
    updateSet: UpdateTemplatesSetType
    partType: string
}

export default function AddPartCard({set, updateSet, partType}: AddPartCardProps){
    const [file, setFile] = useState<File | null>(null)
    const [name, setName] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const onClick = () => {
        const parts = set.parts as ShopperTemplatesSetPartsType
        if (!file || !name || !parts) return

        setIsLoading(true)
        api.shopperTemplates.sets.addPart(
            set.id,
            file,
            partType, name
        ).then(response => {
            setIsLoading(false)
            updateSet(set.id, {
                parts: {
                    ...parts,
                    [response.data.added_part_type]: [response.data.added_part, ...parts[response.data.added_part_type]]
                }
            })

            setFile(null)
            setName('')
        }).catch(err => {
            setIsLoading(false)
            console.log(err)
            alert(err?.response?.data?.detail || 'Unknown error')
        })
    }

    return (
        <MDBCard className={'h-100'}>
            <MDBCardHeader>
                Add part
            </MDBCardHeader>
            <MDBCardBody
                className={'h-100'}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <div/>
                {!isLoading && (
                    <MDBFileUpload
                        acceptedExtensions={'image/png'}
                        getInputFiles={([file]: File[]) => {
                            if (file) {
                                setFile(file ?? null)
                                setName(file?.name || '')
                            }
                        }}
                        style={{height: file ? '100%' : undefined}}
                    />
                )}
                <MDBInput
                    required
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    name={'name'}
                    label={'name'}
                    className={'align-self-end'}
                />
            </MDBCardBody>
            <MDBCardFooter>
                <MDBBtn
                    disabled={!name || !file}
                    onClick={onClick}
                    className={'d-flex align-items-center'}
                >
                    {isLoading && (
                        <MDBSpinner size={'sm'} role={'status'} tag={'span'} className={'me-2'} />
                    )}
                    <span>Add</span>
                </MDBBtn>
            </MDBCardFooter>
        </MDBCard>
    )
}
