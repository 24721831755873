import {TUser} from "react-telegram-auth";
import {MDBBtn} from "mdb-react-ui-kit";


interface LoggedInViewProps {
    user: TUser
    goApp: (user: TUser) => any
    pressButtonToGoAppText: string
    goAppButton: string
}


export default function LoggedInView({user, goApp, pressButtonToGoAppText, goAppButton}: LoggedInViewProps) {
    return (
         <div className={'login-container'}>
             <div>
                 {user.photo_url && (
                     <div className={'d-flex justify-content-center w-100 mb-3'}>
                         <div className={'img-container rounded-circle p-2'}>
                             <img
                                 src={user.photo_url}
                                 alt={'avatar'}
                                 className={'w-100 rounded-circle'}
                             />
                         </div>
                     </div>
                 )}
                 <h4 className={'user-name-text'}>
                     {user.first_name + ' ' + user.last_name}
                 </h4>
                 {user.username && (
                     <h5 className={'user-username-text'}>
                         {'@' + user.username}
                     </h5>
                 )}
                 <p className={'login-container-text'}>
                     {pressButtonToGoAppText}
                 </p>
             </div>
             <div className={'w-100 d-flex justify-content-center'}>
                 <MDBBtn className={'go-app-button'} onClick={() => goApp(user)}>
                     {goAppButton}
                 </MDBBtn>
             </div>
        </div>
    )
}
