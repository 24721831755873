import {
    useClipboard,
    MDBCard,
    MDBCardBody,
    MDBIcon,
    MDBInput,
    MDBBtn,
    MDBCol,
    MDBRow,
    MDBCardTitle, MDBCardFooter, MDBAlert
} from "mdb-react-ui-kit";
import {useEffect, useRef, useState} from "react";


function CreateCompany( { createCompany } ) {
    const [name, setName] = useState('')
    const [comment, setComment] = useState('')

    const getSetValue = (setter) => (e) => {
        setter(e.target.value)
    }

    return (
        <MDBCard className={'h-100'}>
            <MDBCardTitle className={'text-center mt-3'}>Create company</MDBCardTitle>
            <MDBCardBody className={'pb-2'}>
                <form id={'create-company-form'} onSubmit={e => {
                        e.preventDefault()
                        createCompany(name, comment)
                    }}
                >
                    <MDBInput
                        label={'name'} name={'name'} required
                        value={name} onChange={getSetValue(setName)}
                    />
                    <MDBInput
                        className={'mt-2'}
                        label={'comment'} name={'comment'}
                        value={comment} onChange={getSetValue(setComment)}
                    />
                </form>

                <div className={'w-100 text-center mt-2'}>
                    <span className={'mx-1'} style={{fontSize: '.85rem'}}>Token will be created automatically</span>
                </div>
            </MDBCardBody>
            <MDBCardFooter>
                <MDBBtn
                    form={'create-company-form'}
                    type={'submit'}
                    className={'w-100'}
                >
                    Create
                </MDBBtn>
            </MDBCardFooter>
        </MDBCard>
    )
}


function Company( { id, name, comment, accessToken, updateCompany, deleteCompany, revokeToken } ) {
    const [nameValue, setNameValue] = useState('')
    const [commentValue, setCommentValue] = useState('')

    const copyButtonRef = useRef(null)

    useEffect(() => {
        setNameValue(name || '')
    }, [name])

    useEffect(() => {
        setCommentValue(comment || '')
    }, [comment])

    const getSetValue = (setter) => (e) => {
        setter(e.target.value)
    }

    useClipboard(accessToken, copyButtonRef)

    const isDataChanged = name !== nameValue || comment !== commentValue

    return (
        <>
            <MDBAlert
                color={'primary'}
                className={'text-center'}
                autohide
                position={'top-right'}
                width={200}
                offset={20}
                delay={3000}
                appendToBody
                triggerRef={copyButtonRef}
            >
                Copied
            </MDBAlert>
            <MDBCard className={'h-100'}>
                <MDBCardTitle className={'text-center mt-3'}>Edit company #{id} {name}</MDBCardTitle>
                <MDBCardBody className={'pb-2'}>
                    <MDBInput
                        label={'name'} name={'name'}
                        value={nameValue} onChange={getSetValue(setNameValue)}
                    />
                    <MDBInput
                        className={'mt-2'}
                        label={'comment'} name={'comment'}
                        value={commentValue} onChange={getSetValue(setCommentValue)}
                    />

                    <div className={'w-100 text-center mt-2'}>
                        <a ref={copyButtonRef} href={'#'} onClick={(e) => e.preventDefault()}>
                            <MDBIcon far icon="copy" />
                        </a>
                        <span className={'mx-1'} style={{fontSize: '.8rem'}}>{accessToken}</span>
                        <a href={'#'} onClick={(e) => {
                            e.preventDefault()
                            revokeToken(id)
                        }}>
                            <MDBIcon fas icon="sync" />
                        </a>
                    </div>
                </MDBCardBody>
                <MDBCardFooter>
                    <MDBBtn
                        className={'bg-danger me-1 float-left'}
                        onClick={() => deleteCompany(id)}
                    >
                        Delete
                    </MDBBtn>
                    <MDBBtn
                        disabled={!isDataChanged}
                        onClick={() => updateCompany(id, nameValue, commentValue)}
                    >
                        Save changes
                    </MDBBtn>
                </MDBCardFooter>
            </MDBCard>
        </>
    )
}


export function Companies( { companies, revokeToken, updateCompany, createCompany, deleteCompany } ) {
    return (
        <MDBRow className={'align-items-stretch'}>
            <MDBCol size={12} sm={6} md={4} lg={3} key={'create-company'}>
                <CreateCompany createCompany={createCompany}/>
            </MDBCol>
            {companies.map(company => {
                return (
                    <MDBCol size={12} sm={6} md={4} lg={3} key={company.id}>
                        <Company
                            id={company.id}
                            name={company.name}
                            comment={company.comment}
                            accessToken={company.token}
                            revokeToken={revokeToken}
                            updateCompany={updateCompany}
                            deleteCompany={deleteCompany}
                        />
                    </MDBCol>
                )
            })}
        </MDBRow>
    )
}
