import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardFooter, MDBCardHeader,
    MDBCheckbox, MDBIcon, MDBInput, MDBPopconfirm, MDBPopconfirmMessage, MDBSpinner
} from "mdb-react-ui-kit";
import {
    ShopperTemplatesSetType,
    UpdateShopperTemplatesSetDataType
} from "../../../../../../api/ShopperTemplates/ShopperTemplatesSets/types";
import {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {ShopperTemplatesContext} from "../../../types";
import api from "../../../../../../api";


interface ShopperTemplatesSetCardProps {
    set: ShopperTemplatesSetType
    updateSet: (data: UpdateShopperTemplatesSetDataType) => void
    onSetCopied: (template: ShopperTemplatesSetType) => void
    activeCheckboxLabel: string
    privateCheckboxLabel: string
    nameLabel: string
    huePercentLabel: string
    deleteButton: string
    templatesButton: string
    partsButton: string
    default_generateType: string
    generateType: string
    imagesType: string,
    typeText: string
    confirmDeleteMessage: string
    cancelButton: string
}


export default function ShopperTemplatesSetCard({set, updateSet, onSetCopied, ...props}: ShopperTemplatesSetCardProps) {
    const {lang, setShopperTemplatesSets} = useOutletContext<ShopperTemplatesContext>()
    const [isActive, setIsActive] = useState(false)
    const [isPrivate, setIsPrivate] = useState(false)
    const [name, setName] = useState('')
    const [huePercent, setHuePercent] = useState(0)
    const navigate = useNavigate()

    const [isDeleteLoading, setIsDeleteLoading] = useState(false)
    const [isCopyLoading, setIsCopyLoading] = useState(false)
    const [isNameLoading, setIsNameLoading] = useState(false)
    const [isHuePercentLoading, setIsHuePercentLoading] = useState(false)

    useEffect(() => {
        setIsActive(set.is_active)
    }, [set.is_active])

    useEffect(() => {
        setIsPrivate(set.is_private)
    }, [set.is_private])

    useEffect(() => {
        setName(set.name)
        setIsNameLoading(false)
    }, [set.name])

    useEffect(() => {
        setHuePercent(set.hue_percent)
        setIsHuePercentLoading(false)
    }, [set.hue_percent])

    return (
        <MDBCard className={'h-100'}>
            <MDBCardHeader>
                {`#${set.id} ${set.name}`}
            </MDBCardHeader>
            <MDBCardBody className={'px-3'}>
                <MDBCheckbox
                    label={props.activeCheckboxLabel}
                    checked={isActive}
                    onChange={(event) => {
                        setIsActive(event.target.checked)
                        updateSet({
                            is_active: event.target.checked
                        })
                    }}
                />
                <MDBCheckbox
                    wrapperClass={'mt-2'}
                    label={props.privateCheckboxLabel}
                    checked={isPrivate}
                    onChange={(event) => {
                        setIsPrivate(event.target.checked)
                        updateSet({
                            is_private: event.target.checked
                        })
                    }}
                />

                <div className={'d-flex mt-2 align-items-center'}>
                    <MDBInput
                        wrapperClass={'flex-1'}
                        label={props.nameLabel}
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                    {isNameLoading ? (
                        <MDBSpinner className={'ms-2'} role={'status'} size={'sm'} color={'primary'}/>
                    ) : (
                        <a href={'#'} className={'ms-2'} onClick={(event) => {
                            event.preventDefault()
                            if (name === set.name) return

                            setIsNameLoading(true)
                            updateSet({name})
                        }}>
                            <MDBIcon icon={'fa fa-check'} size={'lg'}/>
                        </a>
                    )}
                </div>

                <div className={'d-flex mt-2 align-items-center'}>
                    <MDBInput
                        type={'number'}
                        wrapperClass={'flex-1'}
                        label={props.huePercentLabel}
                        value={huePercent}
                        onChange={(event) => setHuePercent(parseInt(event.target.value))}
                    />
                    {isHuePercentLoading ? (
                        <MDBSpinner className={'ms-2'} role={'status'} size={'sm'} color={'primary'}/>
                    ) : (
                        <a href={'#'} className={'ms-2'} onClick={(event) => {
                            event.preventDefault()
                            if (huePercent === set.hue_percent) return

                            setIsHuePercentLoading(true)
                            updateSet({hue_percent: huePercent})
                        }}>
                            <MDBIcon icon={'fa fa-check'} size={'lg'}/>
                        </a>
                    )}
                </div>

                <div className={'mt-3'}>
                    {props.typeText.replace(
                        '{type}', props[`${set.type}Type`]
                    )}
                </div>
            </MDBCardBody>
            <MDBCardFooter className={'px-3'}>
                <MDBPopconfirm
                    btnClassName={'btn-danger px-3'}
                    confirmBtnClasses={'btn-danger'}
                    cancelBtnText={props.cancelButton}
                    confirmBtnText={props.deleteButton}
                    btnChildren={<>
                        {isDeleteLoading && (
                            <MDBSpinner size={'sm'} role={'status'} tag={'span'} className={'me-2'} />
                        )}
                        {props.deleteButton}
                    </>}
                    onConfirm={() => {
                        setIsDeleteLoading(true)
                        api.shopperTemplates.sets.deleteSet(set.id).then(response => {
                            setIsDeleteLoading(false)
                            setShopperTemplatesSets(prevState => (
                                prevState.filter(el => el.id !== response.data.deleted_set_id)
                            ))
                        }).catch(err => {
                            setIsDeleteLoading(false)
                            console.log(err)
                            alert(err?.response?.data?.detail || 'Unknown error')
                        })
                    }}
                >
                    <MDBPopconfirmMessage>{props.confirmDeleteMessage.replace(
                        '{name}', set.name,
                    )}</MDBPopconfirmMessage>
                </MDBPopconfirm>

                <MDBBtn disabled={isDeleteLoading} className={'ms-1 px-3'} onClick={() => {
                    navigate('/' + lang + '/admin/shopperTemplates/' + set.id)
                }}>
                    {set.type === 'images' ? props.templatesButton : props.partsButton}
                </MDBBtn>
                <MDBBtn disabled={isCopyLoading} className={'ms-1 px-2 btn-secondary'} onClick={() => {
                    setIsCopyLoading(true)
                    api.shopperTemplates.sets.copySet(set.id).then(response => {
                        setIsCopyLoading(false)
                        onSetCopied(response.data)
                    }).catch(err => {
                        setIsCopyLoading(false)
                        console.log(err)
                        alert(err?.response?.data?.detail || 'Unknown error')
                    })
                }}>
                    {isCopyLoading && (
                        <MDBSpinner size={'sm'} role={'status'} tag={'span'} className={'me-1'} />
                    )}
                    <MDBIcon icon={'fa-solid fa-clone'} size={'lg'}/>
                </MDBBtn>
            </MDBCardFooter>
        </MDBCard>
    )
}
