import {MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import ShopperTemplatesSetCard from "./components/ShopperTemplatesSetCard";
import {
    ShopperTemplatesSetType,
    UpdateShopperTemplatesSetDataType
} from "../../../../../api/ShopperTemplates/ShopperTemplatesSets/types";
import api from "../../../../../api";
import useLocalise from "../../../../../hooks/useLocalise";
import {useOutletContext} from "react-router-dom";
import {ShopperTemplatesContext} from "../../types";
import CreateShopperTemplatesSetCard from "./components/CreateShopperTemplatesSetCard";


export default function ShopperTemplatesSetsListPage() {
    const {lang, shopperTemplatesSets, setShopperTemplatesSets} = useOutletContext<ShopperTemplatesContext>()

    const localisation = useLocalise({
        activeCheckboxLabel: {
            variable: 'sts active checkbox label', lang
        },
        privateCheckboxLabel: {
            variable: 'sts private checkbox label', lang
        },
        deleteButton: {
            variable: 'sts delete button', lang,
        },
        templatesButton: {
            variable: 'sts templates button', lang,
        },
        partsButton: {
            variable: 'sts parts button', lang,
        },
        defaultGenerateType: {
            variable: 'sts default generate type', lang,
        },
        generateType: {
            variable: 'sts generate type', lang,
        },
        imagesType: {
            variable: 'sts images type', lang,
        },
        typeText: {
            variable: 'sts type text', lang,
        },
        nameLabel: {
            variable: 'sts name label', lang
        },
        huePercentLabel: {
            variable: 'sts hue percent label', lang,
        },
        typeLabel: {
            variable: 'sts type label', lang,
        },
        createHeader: {
            variable: 'sts create header', lang,
        },
        createButton: {
            variable: 'sts create button', lang,
        },
        confirmDeleteMessage: {
            variable: 'sts confirm delete message', lang,
        },
        cancelDeletionButton: {
            variable: 'sts cancel deletion button', lang
        }
    })


    const getUpdateSet = (id: number) => (data: UpdateShopperTemplatesSetDataType) => {
        api.shopperTemplates.sets.updateSet(id, data).then(response => {
            console.log(response.data)
            setShopperTemplatesSets((prevState: ShopperTemplatesSetType[]) => {
                return (
                    prevState.map(set => {
                        if (set.id === id) {
                            return response.data
                        } else {
                            return set
                        }
                    })
                )
            })
        })
    }

    return (
        <MDBContainer fluid>
            <MDBRow className={'align-items-stretch'} style={{marginTop: '-3rem'}}>
                <MDBCol key={'create-set'} size={12} sm={6} lg={4} xl={3} className={'mt-3'}>
                    <CreateShopperTemplatesSetCard
                        createSetHeader={localisation.createHeader}
                        createSetButton={localisation.createButton}
                        isActiveLabel={localisation.activeCheckboxLabel}
                        isPrivateLabel={localisation.privateCheckboxLabel}
                        nameLabel={localisation.nameLabel}
                        huePercentLabel={localisation.huePercentLabel}
                        typeLabel={localisation.typeLabel}
                        default_generateType={localisation.defaultGenerateType}
                        generateType={localisation.generateType}
                        imagesType={localisation.imagesType}
                    />
                </MDBCol>
                {shopperTemplatesSets.map(set => {
                    return (
                        <MDBCol key={set.id} size={12} sm={6} lg={4} xl={3} className={'mt-3'}>
                            <ShopperTemplatesSetCard
                                set={set}
                                updateSet={getUpdateSet(set.id)}
                                activeCheckboxLabel={localisation.activeCheckboxLabel}
                                privateCheckboxLabel={localisation.privateCheckboxLabel}
                                nameLabel={localisation.nameLabel}
                                huePercentLabel={localisation.huePercentLabel}
                                deleteButton={localisation.deleteButton}
                                partsButton={localisation.partsButton}
                                templatesButton={localisation.templatesButton}
                                default_generateType={localisation.defaultGenerateType}
                                generateType={localisation.generateType}
                                imagesType={localisation.imagesType}
                                typeText={localisation.typeText}
                                confirmDeleteMessage={localisation.confirmDeleteMessage}
                                cancelButton={localisation.cancelDeletionButton}
                                onSetCopied={(template) => {
                                    setShopperTemplatesSets(prevState => [template, ...prevState])
                                }}
                            />
                        </MDBCol>
                    )
                })}
            </MDBRow>
        </MDBContainer>
    )
}
