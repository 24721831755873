import {MDBBtn, MDBCheckbox, MDBInput} from "mdb-react-ui-kit";
import React, {useEffect, useRef} from "react";
import {TLoginButton, TLoginButtonSize} from "react-telegram-auth";
import {BotUsername} from "../../config";


export function LoginForm(
    {
        lang, onTelegramAuth, formOnChange,
        changeTab, onSubmit, formData,
        isIncorrectCredsError, isActive,
        localisedTexts,
    } ) {

    const emailRef = useRef(null)
    const passwordRef = useRef(null)

    useEffect(() => {
        if (!passwordRef.current) {
            return
        }
        setTimeout(() => {
            if (passwordRef.current.value === '' ) {
                passwordRef.current.classList.remove('active')
            } else{
                passwordRef.current.classList.add('active')
            }
        }, 500)
    }, [passwordRef.current && passwordRef.current.value === ''])

    if (isActive === false) {
        return null
    }

    return (
        <form action={'#'} onSubmit={onSubmit}>
            <MDBInput
              className={'mb-4'}
              type={'email'}
              label={localisedTexts.loginEmailLabel}
              value={formData.email}
              id={'loginEmail'}
              inputRef={emailRef}
              required
              onChange={formOnChange('email')}
            />

            <div className={'mb-4'}>
                <MDBInput
                  className={'mb-4'}
                  type={'password'}
                  label={localisedTexts.loginPasswordLabel}
                  value={formData.password}
                  id={'loginPassword'}
                  inputRef={passwordRef}
                  onChange={formOnChange('password')}
                  required
                  aria-describedby={'LoginFormError'}
                />
                {isIncorrectCredsError && (
                    <div id={'LoginFormError'} className={'form-text text-danger'}>
                        {localisedTexts.loginIncorrectEmailOrPasswordText}
                    </div>
                )}
            </div>

            <div className="text-center">
              <p>
                <a href="#" onClick={() => changeTab('tab-forgot-password')}>
                    {localisedTexts.forgotPasswordText}
                </a>
              </p>
            </div>

            <MDBBtn type="submit" block className="mb-4">
                {localisedTexts.loginSubmitButton}
            </MDBBtn>

            <div className={'text-center mb-3'}>
                <p>{localisedTexts.loginWithTelegramText}</p>
                {isActive && <TLoginButton
                    botName={BotUsername}
                    buttonSize={TLoginButtonSize.Large}
                    lang={lang}
                    usePic={false}
                    cornerRadius={20}
                    onAuthCallback={onTelegramAuth}
                    requestAccess={'write'}
                />}
            </div>

            <div className="text-center">
              <p>
                  {localisedTexts.notRegisteredText + ' '}
                  <a href="#" onClick={() => changeTab('tab-register')}>
                      {localisedTexts.registerButton}
                  </a>
              </p>
            </div>
        </form>
    )
}


export function RegisterForm(
    {
        formOnChange,
        formData, setFormData, onSubmit,
        isExistsEmailError
    } ) {
    return (
        <form action={'#'} onSubmit={onSubmit}>
            <div className={'mb-4'}>
                <MDBInput
                  type={'email'}
                  label={'Email'}
                  id={'registerEmail'}
                  required
                  onChange={formOnChange('email')}
                  aria-describedby={'registerEmailError'}
                />

                {isExistsEmailError && (
                    <div id={'registerEmailError'} className={'form-text text-danger'}>
                        Email is already exists. Use other email
                    </div>
                )}
            </div>

            <div className={'mb-4'}>
                <MDBInput
                  type={'password'}
                  label={'Password'}
                  id={'registerPassword'}
                  required
                  onChange={formOnChange('password')}
                  aria-describedby={'registerPasswordError'}
                />

                {formData.password && formData.password.length < 8 && (
                    <div id={'registerPasswordError'} className={'form-text text-danger'}>
                        Password must be minimum 8 symbols
                    </div>
                )}
            </div>

            <div className={'mb-4'}>
                <MDBInput
                  type={"password"}
                  label={'Repeat password'}
                  id={'registerConfirmPassword'}
                  required
                  onChange={formOnChange('confirmPassword')}
                  aria-describedby={'confirmPasswordError'}
                />
                {formData.confirmPassword && formData.password && formData.password !== formData.confirmPassword && (
                    <div id={'confirmPasswordError'} className={'form-text text-danger'}>
                        Passwords do not match
                    </div>
                )}
            </div>

            <MDBCheckbox
              wrapperClass="d-flex justify-content-center mb-4"
              label="I have read and agree to the terms"
              id={'registerAcceptAgreement'}
              checked={formData.acceptAgreement}
              onChange={event => {
                  const data = {acceptAgreement: event.target.checked}
                  if (event.target.checked) {
                      data["acceptAgreementDatetime"] = Date.now() / 1000
                  }
                  setFormData(data)
              }}
            />

            <MDBBtn
                type="submit" block
                className="mb-3"
                disabled={!formData.acceptAgreement}
            >
          Sign up
        </MDBBtn>
    </form>
    )
}


export function WaitForConfirmEmail( {  } ) {
    return (
        <p>Confirm email by letter we sent to you</p>
    )
}
