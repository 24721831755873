import useLoadShopperTemplatesSets from "../../ShopperTemplates/hooks/useLoadShopperTemplatesSets";
import {MDBSelect} from "mdb-react-ui-kit";
import {useEffect} from "react";

interface ShopperTemplatesSetSelectProps {
    label: string
    chosenSetID: number | null
    setChosenSetID: (id: number | null) => void
    itemText: string
    privateText: string
}


export default function ShopperTemplatesSetSelect(props: ShopperTemplatesSetSelectProps) {
    const [shopperTemplatesSets] = useLoadShopperTemplatesSets()

    useEffect(() => {
        if (shopperTemplatesSets.length > 0) {
            if (props.chosenSetID === null) {
                props.setChosenSetID(shopperTemplatesSets[0].id)
            }
        }
    }, [shopperTemplatesSets.length])

    return (
        <MDBSelect
            label={props.label}
            data={shopperTemplatesSets.map(set => ({
                text: props.itemText.replace(
                    '{name}', set.name,
                ).replace(
                '{private}', set.is_private ? props.privateText : '',
                ),
                value: set.id,
                defaultSelected: set.id === props.chosenSetID
            }))}
            onValueChange={(data) => {
                // @ts-ignore
                props.setChosenSetID(data.value)
            }}
            className={'mt-2'}
        />
    )
}
