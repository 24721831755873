import React from "react";
import {MDBBtn} from "mdb-react-ui-kit";

import {getHoverButton, getHoverText} from "./hover";


export function TgButton({className, onClick, children, ...props}) {
    return (
        <MDBBtn
            className={`tg-button p-2 ${className ? className: ''}`}
            {...getHoverButton('--tg-theme-button-color')}
            onClick={onClick ? onClick: () => {}}
            {...props}
        >
            {children}
        </MDBBtn>
    )
}

export function TgLink({className, children, ...props}) {
    return (
        <a
            className={`tg-link ${className ? className: ''}`}
            {...getHoverText('--tg-theme-link-color')}
            {...props}
        >
            {children}
        </a>
    )
}