import {POST} from "./base";


export async function localise(variable, lang, kwargs={}) {
    if (!kwargs) {
        kwargs = {}
    }
    const response = await POST('localise', {
        variable: variable,
        lang: lang,
        kwargs: kwargs
    }, {}, true)

    return response.data.text
}


export async function localiseList(listToLocalise) {
    const data = await POST('localiseList', {
        list_to_localise: listToLocalise,
    })
    return data.texts
}
