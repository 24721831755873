import {useOutletContext} from "react-router-dom";
import {Companies} from "./components";
import {useEffect, useState} from "react";
import {createCompany, deleteCompany, getCompanies, revokeToken, updateCompany} from "../../../api/company";


export default function CompaniesAdminPage() {
    const {token, lang} = useOutletContext()

    const [companies, setCompanies] = useState([])

    useEffect(() => {
        getCompanies(null, token, lang).then(result => {
            if (result) setCompanies(result)
        })
    }, [token, lang])

    const createCompanyAndSaveResult = (name, comment) => {
        createCompany(name, comment, token, lang).then(
            response => {
                if (response.status === 200) {
                    setCompanies([response.data, ...companies])
                }
            }
        )
    }

    const revokeTokenAndSaveResult = (companyID) => {
        revokeToken(companyID, token, lang).then(
            response => {
                if (response.status === 200) {
                    const data = response.data
                    setCompanies(companies.map(
                        company => {
                            if (company.id === data.company_id) {
                                return {...company, token: data.new_token}
                            }
                            return company
                        }
                    ))
                }
            }
        )
    }

    const updateCompanyAndSaveResult = (companyID, name, comment) => {
        updateCompany(companyID, {name, comment}, token, lang).then(
            response => {
                if (response.status === 200) {
                    setCompanies(companies.map(
                        company => {
                            if (company.id === response.data.id) {
                                return response.data
                            }
                            return company
                        }
                    ))
                }
            }
        )
    }

    const deleteCompanyAndSaveResult = (companyID) => {
        deleteCompany(companyID, token, lang).then(
            response => {
                if (response.status === 200) {
                    setCompanies(companies.filter(company => company.id !== response.data.company_id))
                }
            }
        )
    }

    return (
        <div className={'py-3 px-4'}>
            <Companies
                companies={companies}
                createCompany={createCompanyAndSaveResult}
                revokeToken={revokeTokenAndSaveResult}
                updateCompany={updateCompanyAndSaveResult}
                deleteCompany={deleteCompanyAndSaveResult}
            />
        </div>
    )
}
