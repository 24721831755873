import './style.scss'
import {TUser} from "react-telegram-auth";
import {useOutletContext, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import LoginView from "./components/LoginView";
import LoggedInView from "./components/LoggedInView";
import {localiseListAndSave} from "../../helpers";


export default function TGLoginButtonPage() {
    const {lang} = useOutletContext<{lang: string}>()

    const [searchParams] = useSearchParams()
    const [telegramUser, setTelegramUser] = useState<TUser | null>(null)
    const [localisedTexts, setLocalisedTexts] = useState({
        tgLoginText: '',
        pressTgLoginButtonText: '',
        pressButtonToGoAppText: '',
        goAppButton: '',
    })

    useEffect(() => {
        localiseListAndSave(setLocalisedTexts, {
            tgLoginText: {
                variable: 'tg login text',
                lang: lang,
            },
            pressTgLoginButtonText: {
                variable: 'press tg login button text',
                lang: lang,
            },
            pressButtonToGoAppText: {
                variable: 'press button to go app text',
                lang: lang,
            },
            goAppButton: {
                variable: 'go app button',
                lang: lang,
            }
        })
    }, [lang])

    const callbackUrl = searchParams.get("callbackUrl")

    const openCallback = (user: TUser) => {
        if (callbackUrl) {
            const queryParams = new URLSearchParams({data: JSON.stringify(user)})
            window.location.replace(`${callbackUrl}?${queryParams}`)
        }
    }

    return (
        <div className={'d-flex justify-content-center align-items-center'} style={{
            width: '100%',
            height: '100vh',
            backgroundColor: '#EADDF7',
            overflow: 'hidden'
        }}>
            {telegramUser ? (
                <LoggedInView
                    user={telegramUser}
                    goApp={openCallback}
                    pressButtonToGoAppText={localisedTexts.pressTgLoginButtonText}
                    goAppButton={localisedTexts.goAppButton}
                />
            ) : (
                <LoginView
                    lang={lang}
                    onLogin={(user) => {
                        setTelegramUser(user)
                        openCallback(user)
                    }}
                    tgLoginText={localisedTexts.tgLoginText}
                    pressTgLoginButtonText={localisedTexts.pressTgLoginButtonText}
                />
            )}
        </div>
    )
}
