

export const getHeaders = ({token, lang} = {}) => {
    const headers = {}

    if (token) {
        headers['Authorization'] = `bearer ${token}`
    }

    if (lang) {
        headers['Accept-Language'] = lang
    }

    return headers
}