import {AxiosInstance} from "axios";
import ShopperTemplatesSetsApiModule from "./ShopperTemplatesSets";
import {ShopperTemplate, ShopperTemplateDeleted, UpdateShopperTemplateData} from "./types";
import {SetTemplates} from "./ShopperTemplatesSets/types";


export default function ShopperTemplatesApiModule(instance: AxiosInstance) {
    const prefix = 'shoppers/templates/'
    const getUrl = (path: string) => prefix + path

    return {
        create(
            setID: number,
            rarity: keyof SetTemplates,
            name: string,
            photo: File,
            is_active?: boolean,
        ) {
            const data = new FormData()

            data.append('set_id', setID.toString())
            data.append('rarity', rarity)
            data.append('name', name)
            data.append('photo', photo)
            if (is_active !== undefined) {
                data.append('is_active', is_active.toString())
            }

            return instance.post<ShopperTemplate>(getUrl('create'), data)
        },
        delete(templateID: number) {
            return instance.delete<ShopperTemplateDeleted>(getUrl(templateID + '/'))
        },
        update(templateID: number, data: UpdateShopperTemplateData) {
            return instance.patch<ShopperTemplate>(getUrl(templateID + '/'), data)
        },
        sets: ShopperTemplatesSetsApiModule(instance, prefix)
    }
}
