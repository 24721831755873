import {MDBContainer} from "mdb-react-ui-kit";
import useActiveSet from "./hooks/useActiveShopperTemplatesSet";
import Parts from "./components/Parts";
import ImportFromArchive from "./components/ImportFromArchive";
import useLocalise from "../../../../../hooks/useLocalise";
import {useOutletContext} from "react-router-dom";
import {ShopperTemplatesContext} from "../../types";
import Templates from "./components/Templates";


export default function ShopperTemplatesSetPage() {
    const {lang} = useOutletContext<ShopperTemplatesContext>()
    const activeSet = useActiveSet()

    const localisation = useLocalise({
        importPartsButton: {
            variable: 'sts import parts button', lang,
        },
        importTemplatesButton: {
            variable: 'sts import templates button', lang,
        },
        createTemplateHeader: {
            variable: 'st create header', lang,
        },
        createTemplateButton: {
            variable: 'st create button', lang,
        },
        nameLabel: {
            variable: 'sts name label', lang,
        },
        isActiveLabel: {
            variable: 'sts active checkbox label', lang,
        },
        deleteButton: {
            variable: 'st delete button', lang,
        },
        deletePartsTypeButton: {
            variable: 'sts delete parts type button', lang,
        }
    })

    if (!activeSet) {
        return null
    }

    return (
        <MDBContainer fluid className={'pb-5'}>
            <h3 className={'mb-4 text-center'}>{`#${activeSet.id} ${activeSet.name}`}</h3>

            <ImportFromArchive
                text={
                    activeSet.type === 'images'
                    ? localisation.importTemplatesButton
                    : localisation.importPartsButton
                }
            />

            {activeSet.type === 'images' ? (
                <Templates
                    createTemplateHeader={localisation.createTemplateHeader}
                    createTemplateButton={localisation.createTemplateButton}
                    isActiveLabel={localisation.isActiveLabel}
                    nameLabel={localisation.nameLabel}
                    deleteButton={localisation.deleteButton}
                />
            ) : (
                <Parts deletePartsTypeButton={localisation.deletePartsTypeButton}/>
            )}
        </MDBContainer>
    )
}
