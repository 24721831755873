import {MDBContainer} from "mdb-react-ui-kit";
import './style.scss'
import useLocalise from "../../hooks/useLocalise";
import {useOutletContext, useSearchParams} from "react-router-dom";
import {LangContext} from "../../types";
import MarketLinks from "../../components/MarketLinks";


export default function UseCertificatePage() {
    const {lang} = useOutletContext<LangContext>()

    const [searchParams] = useSearchParams()

    const localisation = useLocalise({
        header: {
            variable: 'use certificate header', lang,
        },
        codeText: {
            variable: 'certificate code text', lang,
        },
        incorrectLinkError: {
            variable: 'use certificate incorrect link error', lang,
        },
        scanCodeInAppText: {
            variable: 'scan code in app text', lang,
        }
    })

    const code = searchParams.get('code')

    return (
        <MDBContainer className={'d-flex justify-content-center align-items-center'} fluid style={{
            width: '100%',
            height: '100vh',
            backgroundColor: '#EADDF7',
            overflow: 'hidden',
        }}>
            <div className={'use-certificate-card'}>
                {code ? (
                    <>
                        <h3 className={'header-text text-center'}>
                            {localisation.header}
                        </h3>
                        <h3 className={'code-text text-center'}>
                            {localisation.codeText.replace('{code}', code)}
                        </h3>
                        <p className={'scan-code-in-app-text text-center'}>
                            {localisation.scanCodeInAppText}
                        </p>
                    </>
                ) : (
                    <h3 className={'error-text'}>
                        {localisation.incorrectLinkError}
                    </h3>
                )}
                <div className={'mt-2'}>
                    <MarketLinks/>
                </div>
            </div>
        </MDBContainer>
    )
}
