export default function AdminHomePage() {
    return (
        <div
            className={'w-100'}
            style={{
                marginTop: '30vh',
            }}
        >
            <h1 className={'text-center'}>Welcome to admin page</h1>
        </div>
    )
}
