import {MDBContainer} from "mdb-react-ui-kit";
import './style.scss'
import useLocalise from "../../hooks/useLocalise";
import {useOutletContext} from "react-router-dom";
import {LangContext} from "../../types";
import MarketLinks from "../../components/MarketLinks";
import {useEffect} from "react";


export default function OpenMarketPage() {
    const {lang} = useOutletContext<LangContext>()

    useEffect(() => {
        // Отримуємо інформацію про пристрій
        const userAgent = window.navigator.userAgent;
        const isMobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
        const isMacOS = /Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent);

        // Перевіряємо операційну систему та переадресовуємо на відповідний магазин додатків
        if (isMobile && /iPhone|iPad|iPod/i.test(userAgent)) {
          // Переадресування на App Store для iOS
          window.location.href = 'https://apps.apple.com/app/liplep/id1665933840';
        } else if (isMobile && /Android/i.test(userAgent)) {
          // Переадресування на Google Play для Android
          window.location.href = 'https://play.google.com/store/apps/details?id=com.liplep.app';
        } else if (isMacOS) {
          // Переадресування на App Store для MacOS
          window.location.href = 'https://apps.apple.com/app/liplep/id1665933840';
        }
    }, [])

    const localisation = useLocalise({
        header: {
            variable: 'download app header', lang,
        },
    })

    return (
        <MDBContainer className={'d-flex justify-content-center align-items-center'} fluid style={{
            width: '100%',
            height: '100vh',
            backgroundColor: '#EADDF7',
            overflow: 'hidden',
        }}>
            <div className={'use-certificate-card'} style={{minWidth: '350px'}}>
                <h3 className={'header-text text-center'}>
                    {localisation.header}
                </h3>
                <div className={'mt-2 w-100'}>
                    <MarketLinks/>
                </div>
            </div>
        </MDBContainer>
    )
}
