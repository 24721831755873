import axios from "axios";
import {ApiUrl} from "../config";


export const client = axios.create({
    baseURL: ApiUrl
})

export const GET = async (url, config, returnResponse) => {
    var response = null;
    try {
        response = await client.get(url, config)
        if (returnResponse === true) {
            return response
        }
        return response.data
    } catch (error) {
        console.log(error)
        if (returnResponse === true) {
            return error.response
        }
        return null
    }
}

export const POST = async (url, data, config, returnResponse) => {
    var response = {};
    try {
        response = await client.post(url, data, config)
        if (returnResponse === true) {
            return response
        }
        return response.data
    } catch (error) {
        console.log(error)
        if (returnResponse === true){
            return error.response
        }
        return {}
    }
}

export const PATCH = async (url, data, config, returnResponse) => {
    var response = {};
    try {
        response = await client.patch(url, data, config)
        if (returnResponse === true) {
            return response
        }
        return response.data
    } catch (error) {
        console.log(error)
        if (returnResponse === true){
            return error.response
        }
        return {}
    }
}

export const DELETE = async (url, config, returnResponse) => {
    var response = {};
    try {
        response = await client.delete(url, config)
        if (returnResponse === true) {
            return response
        }
        return response.data
    } catch (error) {
        console.log(error)
        if (returnResponse === true){
            return error.response
        }
        return {}
    }
}
