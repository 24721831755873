import {useEffect, useState} from "react";
import {getShoppersList, updateShopperOwner} from "../../../api/shopper";
import {MDBContainer, MDBToast} from "mdb-react-ui-kit";
import {CreateShopper, Filters, Shoppers} from "./components";
import {useOutletContext} from "react-router-dom";


export default function ShoppersAdminPage() {
    const {token, lang} = useOutletContext()

    const [shoppers, setShoppers] = useState([])
    const [withOwner, setWithOwner] = useState(null)
    const [search, setSearch] = useState('')

    const [alert, setAlert] = useState(null)

    useEffect(() => {
        getShoppersList(withOwner, search, token, lang).then(setShoppers)
    }, [token, lang, withOwner, search])

    const shopperCreated = (response) => {
        const shopper = response.data
        setShoppers([shopper, ...shoppers])
        setAlert({
            success: true,
            text: `Shopper "${shopper.name}" created`,
            date: new Date(),
        })
    }

    const onError = (response) => {
        let text;
        if (response.data && Array.isArray(response.data.detail)) {
            text = response.data.detail.join('\n')
        } else {
            text = response.data && response.data.detail ? response.data.detail : 'Unknown error'
        }

        setAlert({
            success: false,
            text: text,
            date: new Date()
        })
    }

    const updateShopperOwnerAndSaveChanges = (shopperID, ownerID) => {
        const shopper = shoppers[shoppers.findIndex(el => el.id === shopperID)]
        if (!shopper || (shopper.owner ? shopper.owner.id : null) === ownerID) {
            return
        }

        updateShopperOwner(shopperID, ownerID, token, lang).then(
            response => {
                if (response.status === 200) {
                    setShoppers(shoppers.map(shopper => {
                        if (shopper.id === shopperID) {
                            return response.data
                        } else {
                            return shopper
                        }
                    }))
                } else {
                    onError(response)
                }
            }
        )
    }

    return (
        <>
            {alert !== null && (
                <MDBToast
                    key={alert.date}
                    className={'word-wrap-pre-line'}
                    color={alert.success ? 'success' : 'danger'}
                    stacking show width={300}
                    autohide delay={5000} appendToBody position={'top-right'}
                    onHide={() => {
                        setAlert(null)
                    }}
                    headerClasses={'w-100'}
                    headerContent={(
                        <h6 className={'w-100 m-0'}>
                            {
                                alert.success ?
                                    'Success' :
                                    'Error'
                            }
                        </h6>
                    )}
                    bodyContent={alert.text}
                />
            )}
            <MDBContainer fluid className={'text-center'}>
                <div className={'d-flex align-items-center justify-content-center mt-2'} style={{minHeight: '100vh'}}>
                    <CreateShopper
                        token={token} lang={lang}
                        onCreated={shopperCreated}
                        onError={onError}
                    />
                </div>
                <Filters
                    withOwner={withOwner} setWithOwner={setWithOwner}
                    search={search} setSearch={setSearch}
                />
                <Shoppers
                    shoppers={shoppers} token={token} lang={lang}
                    updateShopperOwner={updateShopperOwnerAndSaveChanges}
                />
            </MDBContainer>
        </>
    )
}
