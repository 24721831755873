import {MDBBtn, MDBBtnGroup, MDBSpinner} from "mdb-react-ui-kit";
import {ShopperTemplatesSetPartsType} from "../../../../../../../api/ShopperTemplates/ShopperTemplatesSets/types";
import {useState} from "react";


type PartType = keyof ShopperTemplatesSetPartsType


interface FiltersProps {
    activePart: PartType
    setActivePart: (part: PartType) => void
    deletePartsTypeButton: string
    deletePartsType: (partType: keyof ShopperTemplatesSetPartsType) => Promise<void>
}


const PARTS: PartType[] = [
    'bodies',
    'eyes',
    'hands',
    'horns',
    'mouths',
    'stars',
]


export default function PartsFilters({activePart, setActivePart, deletePartsTypeButton, deletePartsType}: FiltersProps) {
    const [isDeleteLoading, setIsDeleteLoading] = useState(false)

    const checked = (isChecked: boolean) => {
        return isChecked ? 'checked': ''
    }

    const getOnClick = (part: PartType) => () => {
        setActivePart(part)
    }

    return (
        <div className={'d-flex justify-content-center w-100'}>
            <MDBBtnGroup>
                {PARTS.map((part) => {
                    return (
                        <MDBBtn
                            className={checked(part === activePart)}
                            onClick={getOnClick(part)}
                        >
                            {part.charAt(0).toUpperCase() + part.slice(1)}
                        </MDBBtn>
                    )
                })}
                <MDBBtn disabled={isDeleteLoading} className={'btn-danger'} onClick={() => {
                    setIsDeleteLoading(true)
                    deletePartsType(activePart).then(() => setIsDeleteLoading(false))
                }}>
                    {isDeleteLoading && (
                        <MDBSpinner size={'sm'} role={'status'} tag={'span'} className={'me-2'} />
                    )}
                    {deletePartsTypeButton.replace('{type}', activePart)}
                </MDBBtn>
            </MDBBtnGroup>
        </div>
    )
}
