import {
    MDBCard,
    MDBCardBody,
    MDBContainer, MDBToast,
} from "mdb-react-ui-kit";
import {useOutletContext, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getMe} from "../../api/user";
import useMergeState from "../../mergedState";
import {localise} from "../../api/localise";
import {
    BuyLepModal,
    EnergyBar,
    Image,
    LevelInfo,
    NavBar,
    Params, QrScannerModal,
    RenewEnergyModal, ScansLeft,
    TestScanButton,
    Title
} from "./components";
import {MaxEnergy} from "../../config";
import {
    checkScanned,
    createShopper, finishLevelUp,
    getMyShoppersList,
    improveAttribute,
    levelUp,
    renewEnergy,
    resetDailyScans, scanCheck, scanCheckByCode
} from "../../api/shopper";
import {v4 as uuidv4} from "uuid"
import {buyLep} from "../../api/finances";
import {TgButton} from "../../tgTheme";
import {useDocumentTitle} from "../../hooks";
import {localiseListAndSave} from "../../helpers";


export function HomePage(  ) {
    const WebApp = window.Telegram.WebApp
    const {token, setToken, removeToken, user, setUser, lang} = useOutletContext()

    const [searchParams, setSearchParams] = useSearchParams()

    const [shoppers, setShoppers] = useState([])
    const [alert, setAlert] = useState(null)
    const [showBuyLep, setShowBuyLep] = useState(false)
    const [showRenewEnergy, setShowRenewEnergy] = useState(false)

    const [activeShopperIDX, setActiveShopperIDX] = useState(null)
    const [activeShopper, setActiveShopper] = useState({})

    const [showScan, setShowScan] = useState(false)

    const [localisedTexts, setLocalisedTexts] = useMergeState({
        mustLoginText: '',
        lipBalanceText: '',
        lepBalanceText: '',
        efficiencyText: '',
        enduranceText: '',
        luckyText: '',
        intuitionText: '',
        levelText: '',
        pointsText: '',
        levelUpTooltip: '',
        renewEnergyTooltip: '',
        efficiencyImproveTooltip: '',
        enduranceImproveTooltip: '',
        luckyImproveTooltip: '',
        intuitionImproveTooltip: '',
        scanButtonText: '',
        testScanButtonText: '',
        scansLeftText: '',
        alertSuccessText: '',
        alertErrorText: '',
        hoursText: '',
        minutesText: '',
        secondsText: '',
        levelUpgradingTooltip: '',
        timeBeforeFinishText: '',
        levelUpConfirmButton: '',
        levelUpCancelButton: '',
    })

    const {setDocumentTitle} = useDocumentTitle('LipLep')

    const receiptUUUID = searchParams.get("receipt")
    const isActiveShopper = !!(activeShopper && activeShopper.id)
    useEffect(() => {
        if (receiptUUUID && token && isActiveShopper) {
            scanCheckByCode(receiptUUUID, activeShopper.id, token, lang).then(onScan({
                onResponse: () => setSearchParams(
                    Object.fromEntries(Object.entries(searchParams).filter(([key]) => key !== 'receipt'))
                )
            }))
        }
    }, [receiptUUUID, token, isActiveShopper])

    useEffect(() => {
        if (localisedTexts.scanButtonText) {
            WebApp.MainButton.setText(localisedTexts.scanButtonText).onClick(() => setShowScan(true)).show()
        }
    }, [localisedTexts.scanButtonText])

    useEffect(() => {
        setActiveShopper(activeShopperIDX !== null ? shoppers[activeShopperIDX] : {})
    }, [activeShopperIDX, JSON.stringify(shoppers)])

    useEffect(() => {
        if (!activeShopper.name || !lang) {
            return
        }

        localise('home page title', lang, {
            shopper_name: activeShopper.name
        }).then(setDocumentTitle)

    }, [activeShopper.name, lang])

    useEffect(() => {
        localiseListAndSave(setLocalisedTexts, {
            mustLoginText: {variable: 'must login text', lang},
            efficiencyText: {variable: 'efficiency text', lang},
            enduranceText: {variable: 'endurance text', lang},
            luckyText: {variable: 'lucky text', lang},
            intuitionText: {variable: 'intuition text', lang},
            scanButtonText: {variable: 'scan button text', lang},
            testScanButtonText: {variable: 'test scan button text', lang},
            alertSuccessText: {variable: 'alert success text', lang},
            alertErrorText: {variable: 'alert error text', lang},
            hoursText: {variable: 'hours text', lang},
            minutesText: {variable: 'minutes text', lang},
            secondsText: {variable: 'seconds text', lang},
            levelUpgradingTooltip: {variable: 'level upgrading tooltip', lang},
            timeBeforeFinishText: {variable: 'time before finish text', lang},
            levelUpConfirmButton: {variable: 'level up confirm button', lang},
            levelUpCancelButton: {variable: 'level up cancel button', lang},
            createShopperButton: {variable: 'create shopper button', lang},
            launchButtonText: {variable: 'launch button', lang},
            cancelButtonText: {variable: 'cancel scan button', lang},
            renewEnergyTooltip: {variable: 'renew energy tooltip', lang},
            efficiencyImproveTooltip: {variable: 'improve efficiency tooltip', lang},
            enduranceImproveTooltip: {variable: 'improve endurance tooltip', lang},
            luckyImproveTooltip: {variable: 'improve lucky tooltip', lang},
            intuitionImproveTooltip: {variable: 'improve intuition tooltip', lang},
            finishLevelUpButton: {variable: 'finish level up button', lang},
            cancelFinishLevelUpButton: {variable: 'cancel finish level up button', lang},
            openInBrowserButton: {variable: 'open in browser button', lang},
            enterCodeLabel: {variable: 'enter receipt code label', lang},
            enterCodeButtonText: {variable: 'open enter receipt button', lang},
        })
    }, [lang])

    useEffect(() => {
        localise('scans left text', lang, {scans_left: activeShopper.scans_left}).then(
            text => setLocalisedTexts({scansLeftText: text})
        )
    }, [lang, activeShopper.scans_left])

    useEffect(() => {
        localise('finish level up message', lang, {lip: activeShopper.finish_level_up_costs}).then(
            text => setLocalisedTexts({finishLevelUpMessage: text})
        )
    }, [lang, activeShopper.finish_level_up_costs])

    useEffect(() => {
        localiseListAndSave(setLocalisedTexts, {
            lipBalanceText: {variable: 'lip balance text', lang, kwargs: {lip: user.lip}},
            lepBalanceText: {variable: 'lep balance text', lang, kwargs: {lep: user.lep}},
        })
    }, [lang, user.lip, user.lep])

    useEffect(() => {
        if (!token) {
            return
        }

        getMyShoppersList(token, lang).then(
            result => {
                if (result !== null) {
                    setShoppers(result)
                }
            }
        )
    }, [user.id, token, lang])

    useEffect(() => {
        if (!token) {
            return setUser({})
        }

        getMe(token, lang, true).then(
            response => {
                if (response.status === 401) {
                    setToken()
                } else if (response.status === 200) {
                    setUser(response.data)
                }
            }
        )
    }, [token, lang])

    useEffect(() => {
        localiseListAndSave(setLocalisedTexts, {
            levelText: {variable: 'level text', lang, kwargs: {level: activeShopper.level}},
            pointsText: {variable: 'points text', lang, kwargs: {points: activeShopper.points}},
            levelUpTooltip: {variable: 'level up tooltip', lang, kwargs: {costs: activeShopper.level_up_costs}},
        })
    }, [activeShopper.level, activeShopper.level_up_costs, activeShopper.points, lang])

    useEffect(() => {
        if (activeShopperIDX === null) {
            setActiveShopperIDX(shoppers.length > 0 ? 0 : null)
        }
    }, [shoppers.length, activeShopperIDX])

    const updateActiveShopper = (newData) => {
        const newShoppers = shoppers.map((shopper, idx) => {
            if (idx !== activeShopperIDX){
                return shopper
            }
            return {...shopper, ...newData}

        })

        setShoppers(newShoppers)
    }

    const defaultGetTextKwargs = ({data}) => {
        return data
    }

    const afterResponse = ({textVariable, lang, getTextKwargs=defaultGetTextKwargs, onSuccess, onError, onResponse}) => {
        return (response) => {
            if (onResponse) {
                onResponse({response: response, data: response.data})
            }

            if (response.status === 200) {
                if (onSuccess) {
                    onSuccess({response: response, data: response.data})
                }
                const textKwargs = getTextKwargs({response: response, data: response.data})
                localise(textVariable, lang, {...textKwargs, ...response.data}).then(
                    text => {
                        setAlert({
                            success: true,
                            text: text,
                            date: new Date(),
                        })
                    }
                )
            } else {
                if (onError) {
                    onError({response: response, data: response.data})
                }
                let text;
                if (response.data && Array.isArray(response.data.detail)) {
                    text = response.data.detail.join('\n')
                } else {
                    text = response.data && response.data.detail ? response.data.detail : ""
                }

                setAlert({
                    success: false,
                    text: text,
                    date: new Date()
                })
            }
        }
    }

    const onScan = ({onResponse, onError} = {}) => afterResponse({
        textVariable: 'scan success text',
        lang: lang,
        onSuccess: ({data}) => {
            updateActiveShopper({energy: data.energy, scans_left: data.scans_left})
            setUser({...user, lip: data.lip})
        },
        onResponse: onResponse,
        onError: onError,

    })

    const testScan = () => {
        const scanID = uuidv4()

        checkScanned(scanID, activeShopper.id, token, lang).then(onScan())
    }

    const buyLepButtonOnClick = (lepCount) => {
        buyLep(lepCount, token, lang).then(
            afterResponse({
                textVariable: 'lep bought',
                lang: lang,
                getTextKwargs: ({data}) => {
                    return {lep: data.lep_bought}
                },
                onSuccess: ({data}) => {
                    setUser({...user, lip: data.lip, lep: data.lep})
                },
                onResponse: () => setShowBuyLep(false)
            })
        )
    }

    const renewEnergyOnClick = (energyCount) => {
        renewEnergy(energyCount, activeShopper.id, token, lang).then(
            afterResponse({
                textVariable: 'energy renewed',
                lang: lang,
                getTextKwargs: ({data}) => {
                    return {energy: data.energy_renewed}
                },
                onSuccess: ({data}) => {
                    setUser({...user, lip: data.lip})
                    updateActiveShopper({energy: data.energy, scans_left: data.scans_left})
                },
                onResponse: () => setShowRenewEnergy(false)
            }),
        )
    }

    const resetDailyScansOnClick = () => {
        resetDailyScans(activeShopper.id, token, lang).then(
            afterResponse({
                textVariable: 'reset daily scans success',
                lang: lang,
                onSuccess: ({data}) => updateActiveShopper({scans_left: data.scans_left})
            })
        )
    }

    const levelUpOnClick = () => {
        levelUp(activeShopper.id, token, lang).then(
            afterResponse({
                textVariable: 'level up success text',
                lang: lang,
                getTextKwargs: ({data}) => {
                    return {
                        level: data.level,
                        points: data.points_got,
                        lep_spent: data.lep_spent,
                    }
                },
                onSuccess: ({data}) => {
                    updateActiveShopper({
                        level: data.level,
                        points: data.points,
                        level_up_costs: data.level_up_costs,
                        finish_level_up_costs: data.finish_level_up_costs,
                        level_up_finish_datetime: data.level_up_finish_datetime,
                    })
                    setUser({...user, lep: data.lep})
                }
            })
        )
    }

    const finishLevelUpOnClick = () => {
        finishLevelUp(activeShopper.id, token, lang).then(
            afterResponse({
                textVariable: 'level up finished text',
                lang: lang,
                onSuccess: ({data}) => {
                    setUser({...user, lip: data.lip})
                    updateActiveShopper({
                        level: data.level,
                        points: data.points,
                        finish_level_up_costs: data.finish_level_up_costs,
                        level_up_finish_datetime: data.level_up_finish_datetime,
                    })
                }
            })
        )
    }

    const improveAttributeOnClick = (attributeName) => {
        improveAttribute(activeShopper.id, attributeName, token, lang).then(
            afterResponse({
                textVariable: 'improve attribute success text',
                lang: lang,
                getTextKwargs: ({data}) => {
                    return {
                        attribute_name: localisedTexts[`${data.attribute}Text`],
                        new_value: data.new_value,
                    }
                },
                onSuccess: ({data}) => {
                    const newData = {points: data.points}
                    newData[data.attribute] = data.new_value
                    updateActiveShopper(newData)
                }
            })
        )
    }

    const createShopperOnClick = () => {
        createShopper(token, lang, user.id).then(
            afterResponse({
                textVariable: 'shopper successfully created',
                lang: lang,
                onSuccess: ({data}) => {
                    setActiveShopperIDX(shoppers.length)
                    setShoppers([...shoppers, data])
                }
            })
        )
    }

    const scanCheckOnResult = (receiptData, isCode) => {
        const method = isCode ? scanCheckByCode : scanCheck
        method(receiptData, activeShopper.id, token, lang).then(onScan())
    }

    return (
        <>
            {alert !== null && (
                <MDBToast
                    key={alert.date}
                    className={'word-wrap-pre-line'}
                    color={alert.success ? 'success' : 'danger'}
                    stacking show width={300}
                    autohide delay={5000} appendToBody position={'top-right'}
                    onHide={() => {
                        setAlert(null)
                    }}
                    headerClasses={'w-100'}
                    headerContent={(
                        <h6 className={'w-100 m-0'}>
                            {
                                alert.success ?
                                    localisedTexts.alertSuccessText :
                                    localisedTexts.alertErrorText
                            }
                        </h6>
                    )}
                    bodyContent={alert.text}
                />
            )}
            <BuyLepModal
                lang={lang} lip={user.lip}
                show={showBuyLep}
                setShow={setShowBuyLep}
                buyLep={buyLepButtonOnClick}
            >

            </BuyLepModal>
            <RenewEnergyModal
                lang={lang} lip={user.lip}
                show={showRenewEnergy}
                setShow={setShowRenewEnergy}
                renewEnergy={renewEnergyOnClick}
                max={MaxEnergy-activeShopper.energy}
            />
            <MDBContainer fluid className={'p-0 mb-5'} style={{maxWidth: '100vw'}}>
                <NavBar
                    imageUrl={user.photo_url}
                    fullName={user.full_name}
                    openInBrowserButton={localisedTexts.openInBrowserButton}
                    lipBalanceText={localisedTexts.lipBalanceText}
                    lepBalanceText={localisedTexts.lepBalanceText}
                    removeToken={removeToken} setShowBuyLep={setShowBuyLep}
                    showLogout={!WebApp.initData}
                    token={token}
                />
                <MDBCard className={'m-auto tg-card rfs-4'} style={{maxWidth: '400px'}}>
                    <Image shopper={activeShopper}/>
                    <MDBCardBody className={'p-2 w-100'}>
                        <Title
                            isAdmin={user.is_admin}
                            shoppers={shoppers}
                            activeShopperIDX={activeShopperIDX}
                            setActiveShopperIDX={setActiveShopperIDX}
                            createShopper={createShopperOnClick}
                            createShopperButton={localisedTexts.createShopperButton}
                        />
                        <LevelInfo
                            rarity={activeShopper.rarity}
                            localisedTexts={localisedTexts}
                            levelUp={levelUpOnClick}
                            finishLevelUp={finishLevelUpOnClick}
                            levelUpFinishDateTime={activeShopper.level_up_finish_datetime}
                        />
                        <div aria-describedby={'scans-left'}>
                            <EnergyBar
                                energy={activeShopper.energy}
                                localisedTexts={localisedTexts}
                                setShowRenewEnergy={setShowRenewEnergy}
                            />
                        </div>
                        <ScansLeft
                            isAdmin={user.is_admin}
                            scansLeftText={localisedTexts.scansLeftText}
                            resetDailyScans={resetDailyScansOnClick}
                        />
                        {user.is_admin &&
                            (
                                <TestScanButton
                                    scansLeft={activeShopper.scans_left}
                                    buttonText={localisedTexts.testScanButtonText}
                                    className={'mt-4'} onClick={testScan}
                                />
                            )}
                        <hr className={'my-4'}/>
                        <Params
                            shopper={activeShopper}
                            localisedTexts={localisedTexts}
                            improveAttribute={improveAttributeOnClick}
                        />
                    </MDBCardBody>
                </MDBCard>
                {!WebApp.initData && (
                    <div className={'fixed-bottom w-100'} style={{height: '50px'}}>
                        <TgButton className={'w-100 h-100 rounded-0'} onClick={() => setShowScan(true)}>
                            {localisedTexts.scanButtonText}
                        </TgButton>
                    </div>
                )}
            </MDBContainer>

            <QrScannerModal
                show={showScan}
                setShow={setShowScan}
                onResult={scanCheckOnResult}
                enterCodeLabel={localisedTexts.enterCodeLabel}
                enterCodeButtonText={localisedTexts.enterCodeButtonText}
                cancelButtonText={localisedTexts.cancelButtonText}
                launchButtonText={localisedTexts.launchButtonText}
            />
        </>
    )
}
